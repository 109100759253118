import React from 'react'
import './../assets/css/router.css'
import bgImage from './../assets/img/router/background.png'
import casinoImage from './../assets/img/router/casino.png'
import discord from './../assets/img/router/discord.png'
import etaArt from './../assets/img/router/Eta-art.png'
import getRevenue from './../assets/img/router/get-revenue.png'
import raffle from './../assets/img/router/raffle.png'
import staking from './../assets/img/router/staking.png'


import CasinoSvg from './../assets/svg/router/CasinoSvg.js'
import DiscordSvg from './../assets/svg/router/DiscordSvg.js'
import EtaArtSvg from './../assets/svg/router/EtaArtSvg.js'
import GetRevenueSvg from './../assets/svg/router/GetRevenueSvg.js'
import RaffleSvg from './../assets/svg/router/RaffleSvg.js'
import StakingSvg from './../assets/svg/router/StakingSvg.js'

export default function Router() {
  const router = [
    { name: 'casino', src: casinoImage, svg: CasinoSvg, route: '/casino' },
    { name: 'discord', src: discord, svg: DiscordSvg, route: 'https://discord.gg/EHjuXCxSMG' },
    { name: 'eta-art', src: etaArt, svg: EtaArtSvg, route: 'https://magiceden.io/marketplace/yolofoxes' },
    { name: 'get-revenue', src: getRevenue, svg: GetRevenueSvg, route:'/revenue'},
    { name: 'raffle', src: raffle, svg: RaffleSvg, route:'/raffle' },
    { name: 'staked', src: staking, svg: StakingSvg, route:'/staking' }
  ]
  return (
    <div className='router__background-container'>
      <div className='router__background'>
        <img className='router__no-pointer-event-img' alt='router-bg' src={bgImage} />

        {router.map((x, i) => {
          return (<img className='router__no-pointer-event-img' alt={`router-${x.name}`} src={x.src} key={i} />)
        })}

        {router.map((x, i) => {
          return (<x.svg key={i} route={x.route} />)
        })}

      </div>
    </div>
  )
}
