import React, { useContext, useEffect, useState } from 'react'
import { GameContext } from '../../Game'
import preloader from './../../../assets/img/utility/preloader.gif'
import BlackJetImage from './../../../assets/img/casino/CasinoRouterIcons/blackjack.png'
// import FriendIcon from './../../assets/img/casino/FriendsIconBlackJack.png'
import ComputerIcon from './../../../assets/img/casino/ComputerImageBlackJack.png'
import BlackJackInfo from './../../../assets/img/casino/BlackJackInfoIcon.png'
import { blackJack } from '../../../javascript/casino'
import Timestamp from 'react-timestamp'
import config from '../../../config'
import { useWallet } from '@solana/wallet-adapter-react'
import { TableCell, TableRow } from '@mui/material'
// import { LAMPORTS_PER_SOL } from '@solana/web3.js'

export default function BlackJack() {
    const [blackJackInfo, setBlackJackInfo] = useState()
    const { activeTokenState, socket, alertState, setTokens, project } = useContext(GameContext)
    const [alert, setAlert] = alertState
    const { publicKey } = useWallet()
    const [activeToken, setActiveToken] = activeTokenState
    const [displayHowToModal, setDisplayHowToModal] = useState(false)
    const [recentGames, setRecentGames] = useState()
    useEffect(() => {
        try {
            if (socket && socket.id) {
                console.log(socket)
                const socketId = socket.id
                socket.emit('blackjack', {
                    method: 'get-info',
                    data: {
                        walletAddress: publicKey.toString(),
                        projectId: project.id,
                        nonceId: localStorage.getItem('access_id'),
                        signature: localStorage.getItem('signature_sign_in')
                    }
                })
                socket.on('blackjack', async (data) => {
                    if (socketId !== socket.id) return
                    await blackJack(data, blackJackInfo, setBlackJackInfo, setAlert, setTokens, setActiveToken)
                })
            }
        } catch (err) {
            console.log(err)
        }
    }, [socket])
    let amounts = [0.05, 0.1, 0.5, 1, 2, 5, 10]

    if (!blackJackInfo) {
        return (
            <div style={{ minWidth: '100vw', height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
                    <img src={preloader} style={{ width: '200px', maxWidth: '60vw' }} alt='preloader' />
                </div>
            </div>
        )
    } else {
        if (blackJackInfo.started) {
            return (
                <BlackJetStarted blackJackInfo={blackJackInfo} setBlackJackInfo={setBlackJackInfo} />
            )
        } else {
            return (
                <>
                    <HowToModal displayModal={displayHowToModal} setDisplayModal={setDisplayHowToModal} />
                    <div style={{ display: 'flex', flexFlow: 'column wrap' }}>
                        <div className='casino__blackjack__container'>

                            <div className='casino__blackjack__subcontainers'>
                                <div>
                                    <img
                                        style={{
                                            width: '250px',
                                            maxWidth: '95%',
                                            margin: 'auto',
                                            display: 'block'
                                        }} src={BlackJetImage} alt='blackJetImage' />
                                </div>
                                <div style={{ fontSize: '1.5rem', color: 'white', background: '#3b38ad', fontWeight: '700', borderRadius: '0px' }} className='font-montserrat casino__coin-flip__white-text-box-container' >{activeToken.tokenSymbol.toUpperCase()} BLACKJACK</div>
                                <button className='casino__blackjack__blue-btn font-montserrat' onClick={() => setDisplayHowToModal(true)}>How to play?</button>
                            </div>
                            <div className='casino__blackjack__subcontainers' style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexFlow: 'column wrap'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexFlow: 'column wrap'
                                }}>
                                    <div className='casino__coin-flip__options-container casino__blackjack__options-container' style={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        alignItems: 'center',
                                        margin: '1rem'
                                    }}>
                                        <div style={{ fontSize: '1.2rem', color: 'black', fontWeight: '700', marginTop: '10px', width: '80%' }} className='font-montserrat casino__coin-flip__white-text-box-container' >Amount</div>
                                        <div style={{ display: 'flex', flexFlow: 'row wrap', margin: 'auto', justifyContent: 'center' }}>
                                            {(activeToken?.data?.amountOptions ? activeToken?.data?.amountOptions : amounts).map(x => {
                                                let tokenValue = 1
                                                if (activeToken.data && activeToken.data.tokenValue) tokenValue = activeToken.data.tokenValue
                                                return (
                                                    <div className='casino__blackjack__form-radio-btn-box' key={`amount_${x}`}>
                                                        <input type={'radio'} value={`amount_${x}`} id={`amount_${x}`} name='blackjack-amount' />
                                                        <label htmlFor={`amount_${x}`} className='casino__blackjack__form-radio-btn-container' style={{ marginTop: '2px', marginBottom: '2px', marginLeft: '5px', marginRight: '5px' }}>
                                                            <div style={{ textAlign: 'center', display: 'flex', flexFlow: 'row wrap', fontSize: '1rem', color: 'white' }}>
                                                                <div>{x * tokenValue} {activeToken.tokenSymbol}</div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <button className='casino__blackjack__blue-btn font-montserrat' onClick={() => enterGame(socket, activeToken, project, publicKey)} style={{ width: '100px', maxWidth: '80%' }}>
                                        <span><img src={ComputerIcon} style={{ height: '1rem', marginRight: '0.5rem' }} /></span>
                                        Play</button>
                                </div>
                            </div>
                        </div>
                        <div className='casino__blackjack__container'>
                            <RecentGames recentGamesState={[recentGames, setRecentGames]} />
                        </div>
                    </div>
                </>
            )
        }
    }
}

const BlackJetStarted = ({ blackJackInfo, setBlackJackInfo }) => {
    const { tokens, socket, project } = useContext(GameContext)
    const activeToken = tokens.find(x => x.tokenId === blackJackInfo.tokenId)
    const { publicKey } = useWallet()
    return (
        <div className='casino__blackjack__container'>
            <div className='casino__blackjack__subcontainers' style={{ width: 'auto', padding: '1rem' }}>
                <div className='casino__blackjack__started-container'>
                    <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
                        <div className='casino__blackjack__title-text'>Player {'(You)'}</div>
                        <div className='casino__blackjack__cards-value font-signika'>{blackJackInfo.cards.map(x => x.value).reduce((a, b) => a + b)}</div>
                        <div className='casino__blackjack__cards'>
                            {blackJackInfo.cards.map((x, i) => {
                                const cardImage = require(`./../../../assets/img/casino/Cards/${x.card}-${x.color}.png`)
                                return (
                                    <img className='casino__blackjack__card' src={cardImage} key={i} style={i === 0 ? { marginLeft: '0px' } : {}} />
                                )
                            })}
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', padding: '1rem' }}>
                        <button className='casino__blackjack__blue-btn font-montserrat' style={{ width: '100px', maxWidth: '50vw' }} disabled={!blackJackInfo.needToMove} onClick={() => {
                            socket.emit('blackjack', {
                                method: 'hit-or-stand',
                                data: {
                                    move: 'hit',
                                    walletAddress: publicKey.toString(),
                                    projectId: project.id,
                                    nonceId: localStorage.getItem('access_id'),
                                    signature: localStorage.getItem('signature_sign_in')
                                }
                            })
                        }}>Hit</button>
                        <button className='casino__blackjack__blue-btn font-montserrat' style={{ width: '100px', maxWidth: '50vw' }} disabled={!blackJackInfo.needToMove} onClick={() => {
                            socket.emit('blackjack', {
                                method: 'hit-or-stand',
                                data: {
                                    move: 'stand',
                                    walletAddress: publicKey.toString(),
                                    projectId: project.id,
                                    nonceId: localStorage.getItem('access_id'),
                                    signature: localStorage.getItem('signature_sign_in')
                                }
                            })
                        }}>Stand</button>

                        <div>
                            <div className='navbar-token-balance font-montserrat' style={{ color: 'white', display: 'flex', fontSize: '1.5rem', background: '#32b883', borderRadius: '200px', padding: '1rem' }}>{blackJackInfo.betAmount} <img src={activeToken.tokenLogo} style={{ height: '2rem', marginLeft: '0.5rem' }} alt={`${activeToken.tokenSymbol} Logo`} /></div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
                        <div className='casino__blackjack__title-text'>Dealer</div>
                        <div className='casino__blackjack__cards-value font-signika'>{blackJackInfo.opponentsCards.map(x => x.value).reduce((a, b) => a + b)}</div>
                        <div className='casino__blackjack__cards'>
                            {blackJackInfo.opponentsCards.map((x, i) => {
                                const cardImage = require(`./../../../assets/img/casino/Cards/${x.card}-${x.color}.png`)
                                return (
                                    <img className='casino__blackjack__card' src={cardImage} key={i} style={i === 0 ? { marginLeft: '0px' } : {}} />
                                )
                            })}
                        </div>
                    </div>
                </div>
                {blackJackInfo.statusMessage && (
                    <>
                        <div className='font-montserrat casino__blackjack__status-message' style={{ background: blackJackInfo.won && blackJackInfo.won === true ? '#32b079ba' : !blackJackInfo.won ? '#f05e5eba' : '#5e86f0ba' }}>{blackJackInfo.statusMessage} </div>
                        <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row wrap' }}>
                            <button className='casino__blackjack__blue-btn font-montserrat' onClick={() => enterGame(socket, activeToken, project, publicKey, parseFloat((blackJackInfo.betAmount / ((activeToken.data && activeToken.data.tokenValue) || 1)).toFixed(10)))}>Play Again</button>
                            <button className='casino__blackjack__blue-btn font-montserrat' onClick={() => { setBlackJackInfo({}) }}>Back</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

const HowToModal = ({ displayModal, setDisplayModal }) => {
    return (
        <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
            <div className='modal-bg' onClick={() => setDisplayModal(false)}></div>
            <div className='modal-container'>
                <div className='modal font-montserrat' style={{ color: 'white', padding: '1.5rem', justifyContent: 'left', overflowY: 'scroll' }}>
                    <div style={{ display: 'flex', marginLeft: '90%', marginTop: '10px' }}>
                        <div className='modal-close-btn' onClick={() => setDisplayModal(false)}>
                            <div style={{ lineHeight: '1.2em', color: 'black' }}>✖</div>
                        </div>
                    </div>

                    <img src={BlackJackInfo} style={{ width: '250px', maxWidth: '80%', display: 'block', margin: 'auto' }} />
                    <div style={{
                        fontSize: '1.5rem',
                        fontWeight: '700',
                        textTransform: 'uppercase'
                    }}>The play goes as follows:</div>
                    <ul>
                        <li style={{ marginTop: '0.5rem' }}>You get 21 or below and a higher score than the dealer - WIN</li>
                        <li style={{ marginTop: '0.5rem' }}>{`You get 21 or below, and the dealer goes over 21 (goes bust) - WIN`}</li>
                        <li style={{ marginTop: '0.5rem' }}>You get 21 or below, but the dealer beats your score without busting - LOSE</li>
                        <li style={{ marginTop: '0.5rem' }}>You bust, in which case it does not matter what the dealer does - LOSE</li>
                        <li style={{ marginTop: '0.5rem' }}>You and the dealer get the same score, a push, and your bet is returned - PUSH</li>

                        <li style={{ marginTop: '0.5rem' }}>The player's options for playing his or her hand are:

                            <div style={{ marginTop: '1rem' }}>
                                <div>
                                    <span style={{ fontSize: '1.3rem', fontWeight: '900' }}>HIT -</span>
                                    <span className='font-signika'> Take another card.</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: '1.3rem', fontWeight: '900' }}>STAND -</span>
                                    <span className='font-signika'> Take no more cards.</span>
                                </div>
                                {/* Double down: Double the wager, take exactly one more card, and then stand.
                    Split: Double the wager and have each card be the first card in a new hand. This option is available only when both cards have the same value. Sometimes two face cards will be considered acceptable for splitting, as each is 10 points.
                    Surrender: Forfeit half the bet and give up the hand. Surrender was common during the early- and mid-20th century, but is no longer offered at most casinos. */}
                            </div>

                        </li>
                    </ul>

                </div>
            </div>
        </div>

    )
}

const RecentGames = ({ recentGamesState }) => {
    const [recentGames, setRecentGames] = recentGamesState
    const { activeTokenState, project } = useContext(GameContext)
    const [activeToken] = activeTokenState
    useEffect(() => {
        async function getRecentTransactions() {
            try {
                let req = await fetch(`${config.apiURL}/get-recent-games`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        projectId: project.id,
                        tokenId: activeToken.tokenId,
                        gameId: 'blackjack'
                    })
                })
                let res = await req.json()
                if (res.error) return setRecentGames([])
                else return setRecentGames(res)
            } catch {
                return setRecentGames([])
            }
        }
        getRecentTransactions()
    }, [activeToken, project, setRecentGames])
    const explorer = 'https://solscan.io'
    return (
        <div className='casino__recent-games-container' style={{ margin: '1rem 20px' }}>
            {/* <div style={{ fontSize: '2rem', color: 'white', background: '#244c82', fontWeight: '700', borderRadius: '0px' }} className='font-montserrat casino__coin-flip__white-text-box-container' >Recent Games</div> */}
            <div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: '1.5rem', color: 'white', background: '#3b38ad', fontWeight: '700', borderRadius: '20px 20px 0px 0px' }} className='font-montserrat casino__coin-flip__white-text-box-container' >RECENT GAMES</div>
            <div className='casino__recent-games-subcontainer'>
                {recentGames && recentGames.length ? recentGames.map((x, i) => {
                    let color = 'green'
                    let tokenSymbolColor = '#b5f9b5'

                    if (!x.data.win) {
                        color = 'red'
                        tokenSymbolColor = '#ff9f9f'
                    }
                    if (x.data.win === 'push') {
                        color = '#0072ff'
                        tokenSymbolColor = 'rgb(159 207 255)'
                    }
                    return (
                        <div key={i} className='casino__recent-game font-montserrat'>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ border: '0px' }}
                            >
                                <TableCell component="th" scope="row" style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '95px', textAlign: 'center' }}>
                                    <div>
                                        <a style={{ textDecoration: 'none', fontSize: '1rem', color: 'rgb(133 185 255)' }} href={`${explorer}/account/${x.walletAddress}`} target={"_blank"} rel={"noreferrer"}>
                                            [{x.walletAddress.slice(0, 5)}...{x.walletAddress.slice(-2)}]
                                        </a>
                                    </div>
                                </TableCell>
                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '40px', textAlign: 'center' }}>
                                    <div style={{ color: color, fontWeight: '700', textTransform: 'uppercase' }}>{x.data.win === 'push' ? 'Push' : x.data.win ? 'Won' : 'Lost'}</div>
                                </TableCell>

                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '70px', textAlign: 'center' }}>
                                    <div style={{ color: tokenSymbolColor }}>{x.data.win === true ? x.data.prize : x.data.amount} {activeToken.tokenSymbol}</div>
                                </TableCell>

                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '100px', textAlign: 'center' }}>
                                    <Timestamp relative date={new Date(x.playedAt)} autoUpdate style={{ fontSize: '1rem', color: 'white' }} />
                                </TableCell>
                            </TableRow>
                        </div>
                    )
                }) : recentGames && !recentGames.length ?
                    (<div style={{
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '1rem',
                        padding: '1rem',
                        textTransform: 'uppercase'
                    }}>
                        No games played for this token yet!
                    </div>)
                    : (<div style={{ minWidth: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80%', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
                            <img src={preloader} style={{ width: '80px', maxWidth: '60vw' }} alt='preloader' />
                        </div>
                    </div>)
                }
            </div>

        </div >
    )
}

const enterGame = async (socket, activeToken, project, publicKey, amountValue) => {
    let amount;
    const amounts = document.getElementsByName('blackjack-amount');
    for (const amountValue of amounts) {
        if (amountValue.checked && !amount) {
            amount = Number(amountValue.value.replace('amount_', ''))
            break;
        }
    }
    if (amountValue) amount = amountValue
    socket.emit('blackjack', {
        method: 'start-game',
        data: {
            tokenId: activeToken.tokenId,
            betAmount: amount,
            walletAddress: publicKey.toString(),
            projectId: project.id,
            nonceId: localStorage.getItem('access_id'),
            signature: localStorage.getItem('signature_sign_in')
        }
    })
}

