import React, { useContext } from 'react'
// import { CSSTransition } from 'react-transition-group'
import { GameContext } from '../../pages/Game'

export default function Alert() {
    const { alertState } = useContext(GameContext)
    const [alert] = alertState
    if (!alert || !alert.length) return (<></>)
    return (
        <div className='alert'>
            <div className='alert-container'>
                {alert.map((y, i) => {
                    let bgColor = '#af3030'
                    if (y.type === 'success') bgColor = '#008300'
                    if (y.type === 'pending') bgColor = '#0c3a62'
                    return (
                        <div key={i} style={{ backgroundColor: bgColor }} className='font-montserrat alert-info'>
                            {y.message}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}