import React, { useContext } from 'react'
import { GameContext } from '../../pages/Game'

export default function TokenLogo({ activeToken, style }) {
    return (
        <>
            <img src={activeToken.tokenLogo} className='utility__token-symbol' alt={activeToken.tokenSymbol} style={style} />
        </>
    )
}
