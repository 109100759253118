import { showAlert } from "./utility"

export const deposit = (res, setAlert, setActiveToken) => {
    if (res.status === 'error') {
        return showAlert(setAlert, res.message)
    }
    if (res.status === 'success') {
        const amount = res.data.amount
        setActiveToken((prevState) => {
            prevState.balance = parseFloat((prevState.balance + amount).toFixed(10))
            return prevState
        })
        return showAlert(setAlert, res.message, 'success')
    }
}