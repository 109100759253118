import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import casinoBuilding from './../assets/img/casino/casinoBuilding.png'
import gameImage from './../assets/img/casino/gameImage.png'

import { GameContext } from './Game'
export default function GameRouter() {
  return (
    <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }} className='casino__game-router'>
      <CasinoIcon />
      <GamesIcon />
    </div>
  )
}


const CasinoIcon = () => {
  const { project } = useContext(GameContext)
  if ((!project.data?.notIncludedCategories?.includes('casino') && !project.data?.includedCategories) || (project.data.includedCategories && project.includedCategories.includes('casino')) || (!project.data.includedCategories && !project.data.notIncludedCategories)) {
    return (
      <div style={{ margin: '2rem' }}>
        <Link to={`/casino/${project.id}/casino`}>
          <div className='game-building-container'>
            <div className='game-building'>
              <img src={casinoBuilding} alt='casino-building' />
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

const GamesIcon = () => {
  const { project } = useContext(GameContext)
  if ((!project.data?.notIncludedCategories?.includes('games') && !project.data?.includedCategories) || (project.data.includedCategories && project.includedCategories.includes('games')) || (!project.data.includedCategories && !project.data.notIncludedCategories)) {
    return (
      <div style={{ margin: '2rem' }}>
        <Link to={`/casino/${project.id}/games`}>
          <div className='game-building-container'>
            <div className='game-building'>
              <img src={gameImage} alt='games-icon' />
            </div>
          </div>
        </Link>
      </div>
    )
  }
}


