import { useEffect, useMemo, useState } from "react";
import TimerImg from './../../../assets/img/casino/powerball/timer.png'

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const PowerballTimer = ({ deadline = new Date().toString() }) => {
    const parsedDeadline = useMemo(() => new Date(deadline), [deadline]);
    const [time, setTime] = useState(parsedDeadline - Date.now());

    useEffect(() => {
        const interval = setInterval(
            () => setTime(parsedDeadline - Date.now()),
            1000,
        );
        return () => clearInterval(interval);
    }, [parsedDeadline]);

    if (time >= 0) {
        return (
            <div className="casino__powerball__status-purple-container" style={{ padding: '0.2rem 2rem' }}>
                <img src={TimerImg} className='casino__powerball__status-purple-container-bg' alt="subcontainer" />
                <div className='font-signika casino__powerball__status-purple-container-title-text'>ENDING IN</div>
                <div className="casino__powerball__raffle-timer font-signika">
                    {Object.entries({
                        'D': time / DAY,
                        'H': (time / HOUR) % 24,
                        'M': (time / MINUTE) % 60,
                        'S': (time / SECOND) % 60,
                    }).map(([label, value]) => (
                        <div key={label} className="raffle-tool__raffle-timer-columns font-signika">
                            <div className="raffle-timer-time font-signika">
                                <div>{`${Math.floor(value)}`.padStart(2, "0")}{label}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    } else {
        return (
            <div className="raffle-timer" style={{ padding: "5px", display: "block", margin: "auto", opacity: "50%", backgroundColor: "#d35b5b" }}>
                <div className='font-baloo' style={{ display: "block", margin: "auto", textAlign: "center", color: "white", fontSize: "1.4rem", width: "100%" }}>
                    Raffle Ended
                </div>
            </div>

        )
    }
};
