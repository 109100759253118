import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Button, Input, InputLabel, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import { useContext, useEffect, useState } from 'react'
import { showAlert, sleep, verifyWallet } from '../../javascript/utility'
import { GameContext } from '../../pages/Game'
import depositIcon from './../../assets/img/utility/depositIcon.png'
import withdrawIcon from './../../assets/img/utility/withdrawIcon.png'
import { useWallet, useConnection } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from '@solana/web3.js'
import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAccount, getAssociatedTokenAddress } from '@solana/spl-token'
import config from '../../config'
import { deposit } from '../../javascript/socketMethods'

export const WithdrawDepositModal = ({ displayModal, setDisplayModal, setDisplaySignModal }) => {
    const [openedTab, setOpenedTab] = useState('1');
    const [depositAmount, setDepositAmount] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [disableBtn, setDisableBtn] = useState(false)
    const { activeTokenState, websocket, alertState, project } = useContext(GameContext)
    const [activeToken, setActiveToken] = activeTokenState
    const [, setAlert] = alertState
    const { connection } = useConnection()
    const { publicKey, sendTransaction, signTransaction } = useWallet()
    // useEffect(() => {
    //     websocket.addEventListener('message', (message) => {
    //         const res = JSON.parse(message.data)
    //         if (res.method === 'deposit') {
    //             if (res.status === 'error') {
    //                 return showAlert(setAlert, res.message)
    //             }
    //             if (res.status === 'success') {
    //                 const amount = res.data.amount
    //                 setActiveToken((prevState) => {
    //                     prevState.balance += amount
    //                     return prevState
    //                 })
    //                 return showAlert(setAlert, res.message, 'success')
    //             }
    //         }
    //     })
    // }, [])
    const handleChange = (event, newValue) => {
        setOpenedTab(newValue);
    };

    const handleDepositAmountChange = (event) => {
        setDepositAmount(event.target.value)
    }

    const handleWithdrawAmountChange = (event) => {
        setWithdrawAmount(event.target.value)
    }

    const depositTokens = async (depositAmount, websocket, activeToken, setDisableBtn, setAlert) => {
        try {
            setDisableBtn(true)
            if (depositAmount <= 0) {
                setDisableBtn(false)
                showAlert(setAlert, `You cannot deposit less than 0 ${activeToken.tokenSymbol}`)
                return
            }

            let signature;
            if (activeToken.type === 'SOL') {
                const balance = await connection.getBalance(publicKey)
                if (balance / LAMPORTS_PER_SOL < depositAmount) {
                    setDisableBtn(false)
                    showAlert(setAlert, `You cannot deposit more than ${parseFloat(balance / LAMPORTS_PER_SOL)} ${activeToken.tokenSymbol}`)
                    return
                }
                const TransactionObject = {
                    fromPubkey: publicKey,
                    toPubkey: new PublicKey(activeToken.treasuryWallet),
                    lamports: LAMPORTS_PER_SOL * depositAmount
                }
                const transaction = new Transaction().add(
                    SystemProgram.transfer(TransactionObject),
                );
                signature = await sendTransaction(transaction, connection)
                await sleep(2000)
                let blockhash = await connection.getLatestBlockhash();
                await connection.confirmTransaction({ signature: signature, blockhash: blockhash.blockhash, lastValidBlockHeight: blockhash.lastValidBlockHeight });
            }
            if (activeToken.type === 'SPL-TOKEN') {
                const mintToken = new PublicKey(activeToken.data.tokenAddress)
                const recipientAddress = new PublicKey(activeToken.treasuryWallet)

                const transactionInstructions = [];
                const associatedTokenFrom = await getAssociatedTokenAddress(
                    mintToken,
                    publicKey
                );
                const fromAccount = await getAccount(connection, associatedTokenFrom);
                const associatedTokenTo = await getAssociatedTokenAddress(
                    mintToken,
                    recipientAddress
                );
                if (!(await connection.getAccountInfo(associatedTokenTo))) {
                    transactionInstructions.push(
                        createAssociatedTokenAccountInstruction(
                            publicKey,
                            associatedTokenTo,
                            recipientAddress,
                            mintToken
                        )
                    );
                }
                transactionInstructions.push(
                    createTransferInstruction(
                        fromAccount.address,
                        associatedTokenTo,
                        publicKey,
                        depositAmount * (activeToken.data.decimals || LAMPORTS_PER_SOL)
                    )
                );
                const transaction = new Transaction().add(...transactionInstructions);
                const blockHash = await connection.getLatestBlockhash();
                transaction.feePayer = publicKey;
                transaction.recentBlockhash = blockHash.blockhash;
                const signed = await signTransaction(transaction);
                signature = await connection.sendRawTransaction(signed.serialize());
                await connection.confirmTransaction({
                    blockhash: blockHash.blockhash,
                    lastValidBlockHeight: blockHash.lastValidBlockHeight,
                    signature
                });
            }
            setDisplayModal(false)
            showAlert(setAlert, 'Depositing your tokens....', 'pending')
            const sendTransactionReq = async () => {
                const req = await fetch(`${config.apiURL}/deposit-tokens`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        walletAddress: publicKey,
                        amount: Number(depositAmount),
                        signature: signature,
                        tokenId: activeToken.tokenId,
                        projectId: project.id
                    })
                })
                const res = await req.json()
                if (res.status === 'error' && res.message === 'transaction not found') {
                    const res2 = await sendTransactionReq()
                    return res2
                } else {
                    return res
                }
            }
            const res = await sendTransactionReq()
            await deposit(res, setAlert, setActiveToken)
            setDisableBtn(false)
        } catch (err) {
            console.log(err)
            setDisableBtn(false)
            showAlert(setAlert, 'An error occured while depositing tokens')
        }
    }

    const withdrawToken = async (withdrawAmount, setDisableBtn) => {
        try {
            setDisableBtn(true)
            showAlert(setAlert, 'Withdrawing your tokens....', 'pending')
            const sendWithdrawReq = async () => {
                if (activeToken.tokenId !== 'SOLANA_TOKEN') {
                    const mintToken = new PublicKey(activeToken.data.tokenAddress)
                    const pub = new PublicKey(publicKey)
                    if (!mintToken) {
                        showAlert(setAlert, `An unknown error occured`)
                        return setDisableBtn(false)
                    }
                    const associatedTokenTo = await getAssociatedTokenAddress(
                        mintToken,
                        pub
                    );
                    if (!(await connection.getAccountInfo(associatedTokenTo))) {
                        const tx = new Transaction()
                        tx.add(
                            createAssociatedTokenAccountInstruction(
                                pub,
                                associatedTokenTo,
                                pub,
                                mintToken
                            )
                        )
                        let sign = await sendTransaction(tx, connection)
                        await connection.confirmTransaction(sign, 'processed');
                    }
                }

                const req = await fetch(`${config.apiURL}/withdraw-tokens`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        walletAddress: publicKey,
                        amount: Number(withdrawAmount),
                        signature: localStorage.getItem('signature_sign_in'),
                        nonceId: localStorage.getItem('access_id'),
                        tokenId: activeToken.tokenId,
                        projectId: project.id
                    })
                })
                const res = await req.json()
                return res
            }
            const res = await sendWithdrawReq()
            if (res.status === 'error') {
                if (res.data.nonceFail) {
                    setDisableBtn(false)
                    setDisplayModal(false)
                    return setDisplaySignModal(true)
                } else {
                    setDisableBtn(false)
                    return showAlert(setAlert, res.message)
                }
            } else {
                setActiveToken((prevState) => {
                    prevState.balance = parseFloat((prevState.balance - res.data.amount).toFixed(10))
                    return prevState
                })
                setDisableBtn(false)
                return showAlert(setAlert, res.message, 'success')
            }
        } catch (err) {
            setDisableBtn(false)
            showAlert(setAlert, 'An error occured while withdrawing tokens')
        }
    }
    if (!displayModal) {
        return (<></>)
    } else {
        return (
            <>
                <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
                    <div className='modal-bg' onClick={() => setDisplayModal(false)}></div>
                    <div className='modal-container'>
                        <div className='modal'>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={openedTab}>
                                    <div style={{ display: 'flex', marginLeft: '90%', marginTop: '10px' }}>
                                        <div className='modal-close-btn' onClick={() => setDisplayModal(false)}>
                                            <div style={{ lineHeight: '1.2em' }}>✖</div>
                                        </div>
                                    </div>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label={<><img src={depositIcon} style={{ height: '1.7rem' }} alt={`Deposit Icon`} /><div style={{ marginLeft: '10px' }}>Deposit</div></>} value="1" style={{ display: 'flex', flexFlow: 'row wrap', color: 'white', borderRadius: '10px' }} />
                                            <Tab label={<><img src={withdrawIcon} style={{ height: '1.7rem' }} alt={`Deposit Icon`} /><div style={{ marginLeft: '10px' }}>Withdraw</div></>} value="2" style={{ display: 'flex', flexFlow: 'row wrap', color: 'white', borderRadius: '10px' }} />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'row wrap' }}>
                                            <InputLabel htmlFor='deposit-amount-input' style={{ color: 'white', marginRight: '10px' }}>Amount:</InputLabel>
                                            <Input value={depositAmount} onChange={handleDepositAmountChange} style={{ background: 'white', borderRadius: '10px', padding: '2px 10px', color: 'black' }} id='deposit-amount-input' type={'number'} />
                                            <Button style={{ marginTop: '10px', background: 'rgb(44, 44, 79)', color: 'white' }} disabled={disableBtn} onClick={() => depositTokens(depositAmount, websocket, activeToken, setDisableBtn, setAlert)}><img src={activeToken.tokenLogo} style={{ height: '1.3rem', marginRight: '10px' }} alt={`${activeToken.tokenSymbol} Logo`} />Deposit</Button>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'row wrap' }}>
                                            <InputLabel htmlFor='withdraw-amount-input' style={{ color: 'white', marginRight: '10px' }}>Amount:</InputLabel>
                                            <Input value={withdrawAmount} onChange={handleWithdrawAmountChange} style={{ background: 'white', borderRadius: '10px', padding: '2px 10px', color: 'black' }} id='withdraw-amount-input' type={'number'} />
                                            <Button style={{ marginTop: '10px', background: 'rgb(44, 44, 79)', color: 'white' }} disabled={disableBtn} onClick={() => withdrawToken(withdrawAmount, setDisableBtn)}><img src={activeToken.tokenLogo} style={{ height: '1.3rem', marginRight: '10px' }} alt={`${activeToken.tokenSymbol} Logo`} />Withdraw</Button>
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export const SignModal = ({ displayModal, setDisplayModal }) => {
    const { signMessage, publicKey } = useWallet()
    const { alertState, project, socket } = useContext(GameContext)
    const [, setAlert] = alertState
    if (!displayModal) {
        return (<></>)
    } else {
        return (
            <>
                <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
                    <div className='modal-bg'></div>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div style={{ justifyContent: 'center', textAlign: 'center', color: 'white', fontFamily: 'Montserrat', fontSize: '1.5rem', fontWeight: '600', padding: '2rem' }}>Sign Message
                                <div style={{ fontSize: '1rem', marginTop: '1.5rem' }}>You need to sign message to verify ownership of wallet</div>
                                <div className='font-montserrat utility-btns__purple-btn' style={{ margin: '1rem' }} onClick={() => verifyWallet(signMessage, setAlert, setDisplayModal, publicKey, project.id, socket)}>
                                    Sign Message
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}