import React, { useContext } from 'react'
import { Route, Routes } from 'react-router'
import { Link } from 'react-router-dom'
import './../../css/game.css'
import { GameContext } from '../Game'
import Racing from './racing/Racing.js'

const casinoGames = [
    { name: 'Race', route: 'racing', element: <Racing /> },
]
export default function Games() {
    const { project } = useContext(GameContext)
    return (
        <Routes>
            <Route path='/' element={<GameRouter />} />
            {casinoGames.map((x, i) => {
                let included = true
                if (project.data.includedGames) {
                    if (!project.data.includedGames.includes(x.route)) {
                        included = false
                    }
                }
                if (project.data.notIncludedGames) {
                    if (project.data.notIncludedGames.includes(x.route)) {
                        included = false
                    }
                }
                if (included) {
                    return (
                        <Route path={`/${x.route}`} element={x.element} key={i} />
                    )
                } else {
                    return (<></>)
                }
            })}
        </Routes>
    )
}

const GameRouter = () => {
    return (
        <div className='game-list'>
            <RouterImages />
        </div>
    )
}

const RouterImages = () => {
    const { project } = useContext(GameContext)
    return (
        casinoGames.map((x, i) => {
            let icon;
            if (project.data?.images?.games[x.route]) {
                icon = project.data?.images?.games[x.route]
            } else {
                try {
                    icon = require(`./../../assets/img/games/GameRouterIcons/${x.route}.gif`)
                } catch {
                    icon = require(`./../../assets/img/games/GameRouterIcons/${x.route}.png`)
                }
            }

            let included = true
            if (project.data.includedGames) {
                if (!project.data.includedGames.includes(x.route)) {
                    included = false
                }
            }
            if (project.data.notIncludedGames) {
                if (project.data.notIncludedGames.includes(x.route)) {
                    included = false
                }
            }
            console.log(included)
            if (included) {
                return (
                    <div style={{ margin: '1.3rem' }} key={i}>
                        <Link to={`/casino/${project.id}/games/${x.route}`} style={{ textDecoration: 'none' }}>
                            <div className='game-list__game-container' id={`game-list__game-container__${x.route}`}>
                                <img src={icon} alt={x.name} id={`game-list__game-container__${x.route}-img`} />
                                <div className='game-list__game-container-text font-montserrat' id={`game-list__game-container__${x.route}-name`}>
                                    {project.data?.routerNames?.games[x.route] || x.name}
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            } else {
                return (<></>)
            }
        })
    )
}
