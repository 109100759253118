import React, { useContext, useEffect, useRef, useState } from 'react'
import { GameContext } from '../../Game'
import { nFormatter, sleep, useCheckMobileScreen } from '../../../javascript/utility'
import { Button, TableCell, TableRow } from '@mui/material'
import preloader from './../../../assets/img/utility/preloader.gif'
import { raceEnter } from '../../../javascript/game'
import { useWallet } from '@solana/wallet-adapter-react'
import { CSSTransition, Transition } from 'react-transition-group'
import config from '../../../config'
import Timestamp from 'react-timestamp'
export default function Racing() {
    const { activeTokenState, socket, alertState, setTokens, displaySignModalState } = useContext(GameContext)
    const [, setDisplaySignModal] = displaySignModalState
    const { publicKey } = useWallet()
    const [activeToken, setActiveToken] = activeTokenState
    const [, setAlert] = alertState
    const [numberOfSpots, setNumberOfSpots] = useState(1)
    const [disabledBtn, setDisabledBtn] = useState(false)
    const [raceStatus, setRaceStatus] = useState({
        entries: [],
        racingRoundStatus: 'Betting Round',
        nextRoundStatusUpdate: 1680426376000,
        winningHorse: 'red'
    })
    const [entries, setEntries] = useState([])
    const [spritesRun, setSpritesRun] = useState()
    useEffect(() => {
        async function socketCrashHandler(data) {
            try {
                if (data.data.tokenId === activeToken.tokenId && data.data.projectId === project.id) {
                    if (data.type === 'game-update') {
                        if (data.data.winningSprite && !raceStatus.spritesRun) {
                            if (spritesRun) data.data.spritesRun = spritesRun
                            else {
                                let times = []
                                for (var i = 0; i < project.data.racing.sprites.length; i++) {
                                    let time = (Math.random() * (10 - 8 + 1)) + 8;

                                    if (!times.includes(time)) times.push(time)
                                    else i--
                                    continue
                                }
                                let minNum = Math.min.apply(Math, times)
                                console.log(minNum)
                                let indexOfWinning = project.data.racing.sprites.map(x => x.id).indexOf(data.data.winningSprite)
                                data.data.spritesRun = project.data.racing.sprites.map((y, i) => { return { time: y.id === data.data.winningSprite ? minNum : times[i] === minNum ? times[indexOfWinning] : times[i], sprite: y.id, running: y.running } })
                            }
                        } else {
                            setSpritesRun()
                        }
                        setRaceStatus(data.data)
                        if (data.data.entries) {
                            setEntries(data.data.entries)
                        } else {
                            setEntries([])
                        }
                    }
                }
            } catch (err) {
                console.log(err)
            }

        }
        socket.on('racing', socketCrashHandler)

        return () => socket.off('racing', socketCrashHandler);
    }, [socket, activeToken])

    const { project } = useContext(GameContext)
    const isMobile = useCheckMobileScreen()
    return (
        <div className='game__racing__container'>
            <div className='game__racing__tracks-container'>
                <div className='game__racing__status-text'>
                    {raceStatus.racingRoundStatus === 'Early Betting' ? 'Waiting for players...' : raceStatus.racingRoundStatus === 'Betting Round' ? (
                        <div>
                            Starting in:
                            <div>{Math.round((raceStatus.nextRoundStatusUpdate - Date.now()) / 1000)}</div>
                        </div>
                    ) : (<></>)}
                </div>
                {project.data?.racing?.tracks?.map((x, i) => {
                    return (
                        <div key={i} className='game__racing__track-container'>
                            {raceStatus.spritesRun && (<SpriteRunning x={raceStatus.spritesRun[i]} />)}

                            <img src={x.img} alt={i} />
                        </div>
                    )
                })}
            </div>
            <div className='game__racing__tracks-info' style={{ backgroundImage: `url(${project.data?.racing?.backgroundImage})` }}>
                <div style={{ height: '100%', width: '100%', background: 'rgba(0, 0, 0, 0.747)' }} className='game__racing__tracks-bg'>
                    <img src={project.data?.racing?.iconImage} alt='Race Icon' className='game__racing__tracks-info__logo-icon' />
                    <div className='flex flex-row justify-center flex-wrap'>
                        {project.data?.racing?.sprites?.map(x => {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row wrap' }}>
                                    <input type='radio' id={`game__racing__tracks__sprite-${x.id}`} name='game__racing__sprite__choose' className='game__racing__tracks__sprite-choose' />
                                    <label htmlFor={`game__racing__tracks__sprite-${x.id}`}>
                                        <img src={x.standing} style={{ width: isMobile ? '60px' : '100px' }} alt={x.id} />
                                    </label>
                                </div>
                            )
                        })}

                        <div className='utility__forms__grey-form' style={{ padding: '0.5rem 0px' }}>
                            <div className='field half required' style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', margin: 'auto', alignItems: 'center', background: '#3a3a76' }}>
                                <div className='label font-share-tech' htmlFor='game__racing__tracks__input-number-of-spots' style={{ padding: '0px 1rem' }}>Number of Spots:</div>
                                <input value={numberOfSpots} className='text-input font-share-tech' id='game__racing__tracks__input-number-of-spots' type='number' onChange={(event) => setNumberOfSpots(event.target.value)} style={{ width: '100px', maxWidth: '100%' }} />
                            </div>
                        </div>

                        <Button disabled={disabledBtn} onClick={() => raceEnter(numberOfSpots, setAlert, setDisabledBtn, publicKey, activeToken, project.id, setDisplaySignModal, setActiveToken, setTokens)} style={{
                            fontFamily: 'Share Tech',
                            fontSize: '1.5rem',
                            background: '#182a74',
                            color: 'white',
                            padding: '0.1rem 1rem',
                            textTransform: 'inherit'
                        }}>Place Bet</Button>
                    </div>
                </div>
            </div>
            <EntriesList entries={entries} raceStatus={raceStatus} />
            <RecentGames />
        </div>
    )
}

const SpriteRunning = ({ x }) => {
    const [style, setStyle] = useState({ position: 'absolute', left: 0, transition: 'all 0.4s ease-in 0s' })
    useEffect(() => {
        async function updateStyle() {
            setStyle({ position: 'absolute', left: 0, transition: 'all ease-in 0s' })
            await sleep(200)
            setStyle({ position: 'absolute', left: '90%', transition: `all ease-in ${x.time}s` })
        }
        updateStyle()

    }, [])
    return (
        <div className='game__racing_sprite_running'>
            <img style={{ ...style, top: '0px', bottom: '0px', height: '100%' }} src={x.running} alt={x.id} />
        </div>
    )
}


const EntriesList = ({ entries, raceStatus }) => {
    const isMobile = useCheckMobileScreen()
    const { project } = useContext(GameContext)
    if (entries?.length) {
        return (
            <div style={{ background: '#070b1c', width: '700px', maxWidth: 'calc(100vw - 1rem)', padding: '0.5rem' }}>
                <div className='flex flex-row game_racing_entries-table'>
                    {project.data?.racing?.sprites.map(x => {
                        return (
                            <div className='flex flex-column' style={{ flexFlow: 'column', width: '100%' }}>
                                <div className='game_racing_entry'>
                                    {x.name}
                                </div>
                                {entries.filter(y => y.sprite === x.id).map(z => {
                                    return (
                                        <div className='game_racing_entry' style={{ border: `2px solid ${(raceStatus.winningSprite && raceStatus.nextRoundStatusUpdate - Date.now() < 9000) ? (raceStatus?.winningSprite === x.id ? '#97fd97' : '#fd9797') : '#464646'}`, fontSize: isMobile ? '0.8rem' : '1rem' }}>
                                            {z.walletAddress?.slice(0, 3)}...{z.walletAddress?.slice(z.walletAddress.length - 3, z.walletAddress.length)}: {z.numberOfSpots}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}



const RecentGames = () => {
    const [recentGames, setRecentGames] = useState()
    const { activeTokenState, project } = useContext(GameContext)
    const [activeToken] = activeTokenState
    useEffect(() => {
        async function getRecentTransactions() {
            try {
                let req = await fetch(`${config.apiURL}/get-recent-games`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        projectId: project.id,
                        tokenId: activeToken.tokenId,
                        gameId: 'racing-game'
                    })
                })
                let res = await req.json()
                if (res.error) return setRecentGames([])
                else return setRecentGames(res.slice(0, 15))
            } catch {
                return setRecentGames([])
            }
        }
        getRecentTransactions()
    }, [activeToken, project, setRecentGames])
    return (
        <div className='casino__recent-games-container' style={{ width: 'calc(700px + 1rem)', maxWidth: 'calc(100vw - 1rem)', background: 'linear-gradient(to bottom right, #000000, #000000)' }}>
            {/* <div style={{ fontSize: '2rem', color: 'white', background: '#244c82', fontWeight: '700', borderRadius: '0px' }} className='font-montserrat casino__coin-flip__white-text-box-container' >Recent Games</div> */}
            <div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: '1.5rem', color: 'white', background: '#3b38ad', fontWeight: '700', borderRadius: '20px 20px 0px 0px' }} className='font-montserrat casino__coin-flip__white-text-box-container' >RECENT GAMES</div>
            <div className='casino__coin-flip__recent-transactions' style={{ overflow: 'scroll' }}>
                {recentGames && recentGames.length ? recentGames.map((x, i) => {
                    return (
                        <div key={i} className='casino__recent-game font-montserrat' style={{ border: '1px solid rgb(1 34 6 / 74%)', backgroundColor: 'transparent' }}>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ border: '0px' }}
                            >
                                <TableCell component="th" scope="row" style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '95px', textAlign: 'center', fontSize: '1.2rem', color: '#5eff7f' }}>
                                    {x.data?.pot} {activeToken.tokenSymbol}
                                </TableCell>
                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '40px', textAlign: 'center', fontSize: '1.2rem', color: 'white' }}>
                                    {x.data?.entries} entries
                                </TableCell>
                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '40px', textAlign: 'center', fontSize: '1.2rem', color: 'white' }}>
                                    <Timestamp relative date={new Date(x.playedAt)} autoUpdate style={{ fontSize: '1.2rem', color: 'white' }} />
                                </TableCell>
                            </TableRow>
                        </div>
                    )
                }) : recentGames && !recentGames.length ?
                    (<div style={{
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '1rem',
                        padding: '1rem',
                        textTransform: 'uppercase'
                    }}>
                        No games played yet!
                    </div>)
                    : (<div style={{ minWidth: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80%', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
                            <img src={preloader} style={{ width: '80px', maxWidth: '60vw' }} alt='preloader' />
                        </div>
                    </div>)
                }
            </div>

        </div >
    )
}
