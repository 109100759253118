import React, { useContext, useEffect, useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import { GameContext } from './../../Game.js'
import config from '../../../config.js'
import Timestamp from 'react-timestamp'
import { coinFlip } from '../../../javascript/casino.js'
import preloader from './../../../assets/img/utility/preloader.gif'
import { nFormatter } from '../../../javascript/utility.js'
import { TableCell, TableRow } from '@mui/material'
import CoinFlipIllustration from './../../../assets/img/yolo/coinFlipIllustration.png'
export default function CoinFlip() {
    const [recentGames, setRecentGames] = useState()
    return (
        <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', padding: '1rem 0px' }} className='casino__coin-flip__root-container'>
            <FlipOptionForm recentGamesState={[recentGames, setRecentGames]} />
            {/* <RecentGames recentGamesState={[recentGames, setRecentGames]} /> */}
        </div>
    )
}


export const FlipOptionForm = (props) => {
    const [config, setConfig] = useState({
        rateOptions: [
            {
                rate: 1.5,
                chancesOfWinning: 65
            },
            {
                rate: 2,
                chancesOfWinning: 50
            },
            {
                rate: 4,
                chancesOfWinning: 25
            },
            {
                rate: 10,
                chancesOfWinning: 10
            }
        ],
        amountOptions: [0.05, 0.1, 0.5, 1, 2, 5, 10],
        choices: ['Head', 'Tail']
    })
    const [, setRecentGames] = props.recentGamesState
    const { alertState, activeTokenState, project, displaySignModalState } = useContext(GameContext)
    const [, setDisplaySignModal] = displaySignModalState
    const [activeToken, setActiveToken] = activeTokenState
    const [, setAlert] = alertState
    const [disableBtn, setDisableBtn] = useState(false)
    const { publicKey } = useWallet()
    console.log(project)
    return (
        <div className='casino__coin-flip__flip-options-form' style={{ paddingTop: '0px', margin: '1rem 20px' }}>
            <div className='font-montserrat casino__coin-flip__white-text-box-container' >
                <div>FOX FLIP</div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div id="casino__coin-flip__cont">
                        <div id="casino__coin-flip__coin">
                            <img className="front" src={project.data.coinFlip && project.data.coinFlip.frontImage ? project.data.coinFlip.frontImage : 'https://media.discordapp.net/attachments/879937579749883944/1044677232020439040/unknown.png'} alt='coin-front' />
                            <img className="back" src={project.data.coinFlip && project.data.coinFlip.backImage ? project.data.coinFlip.backImage : 'https://media.discordapp.net/attachments/879937579749883944/1044677885958553680/tail.png'} alt='coin-back' />
                        </div>
                    </div>
                </div>
            </div>
            <form id='casino__coin-flip__form' style={{ padding: '0px 20px' }} onSubmit={(event) => coinFlip(event, setAlert, setDisableBtn, publicKey, activeToken, project.id, setActiveToken, setDisplaySignModal, setRecentGames, project)}>

                {!project.data.noCoinFlipMultiplier && (
                    <>
                        <div className='casino__coin-flip__options-container'>

                            <div style={{ fontSize: '1.2rem', color: 'black', fontWeight: '700' }} className='font-montserrat casino__coin-flip__white-text-box-container-inner' >Multiplier</div>
                            <div style={{ display: 'flex', flexFlow: 'row wrap', margin: 'auto', justifyContent: 'center' }}>
                                {config.rateOptions.map(x => {
                                    return (
                                        <div className='casino__coin-flip__form-radio-btn-box' key={x.rate}>
                                            <input type={'radio'} value={`multiplier_${x.rate}`} id={`multiplier_${x.rate}`} name='multiplier' />
                                            <label htmlFor={`multiplier_${x.rate}`} className='casino__coin-flip__form-radio-btn-container' style={{ marginTop: '2px', marginBottom: '2px', marginLeft: '5px', marginRight: '5px' }}>
                                                <div style={{ textAlign: 'center', display: 'flex', flexFlow: 'row wrap', fontSize: '1.8rem', color: 'white' }}>
                                                    <div style={{ fontSize: '1rem' }}>{x.rate}x</div>
                                                </div>
                                                <div style={{ display: 'block', textAlign: 'center', width: '100%', color: 'white', fontSize: '0.8rem' }}>
                                                    {x.chancesOfWinning}%
                                                </div>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                )}

                <div className='casino__coin-flip__options-container'>

                    <div style={{ fontSize: '1.2rem', color: 'black', fontWeight: '700', marginTop: '10px' }} className='font-montserrat casino__coin-flip__white-text-box-container-inner' >Amount</div>
                    <div style={{ display: 'flex', flexFlow: 'row wrap', margin: 'auto', justifyContent: 'center' }}>
                        {(activeToken.data.amountOptions ? activeToken.data.amountOptions : config.amountOptions).map(x => {
                            let tokenValue = 1
                            if (activeToken.data && activeToken.data.tokenValue) tokenValue = activeToken.data.tokenValue
                            return (
                                <div className='casino__coin-flip__form-radio-btn-box' key={`amount_${x}`}>
                                    <input type={'radio'} value={`amount_${x}`} id={`amount_${x}`} name='amount' />
                                    <label htmlFor={`amount_${x}`} className='casino__coin-flip__form-radio-btn-container' style={{ marginTop: '2px', marginBottom: '2px', marginLeft: '5px', marginRight: '5px' }}>
                                        <div style={{ textAlign: 'center', display: 'flex', flexFlow: 'row wrap', fontSize: '1rem', color: 'white' }}>
                                            <div>{x * tokenValue < 1000 ? x * tokenValue : nFormatter(x * tokenValue, 1)} {activeToken.tokenSymbol}</div>
                                        </div>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className='casino__coin-flip__options-container'>

                    <div style={{ fontSize: '1.2rem', color: 'black', fontWeight: '700', marginTop: '10px' }} className='font-montserrat casino__coin-flip__white-text-box-container-inner' >Your Bet</div>
                    <div style={{ display: 'flex', flexFlow: 'row wrap', margin: 'auto', justifyContent: 'center' }}>
                        {config.choices.map(x => {
                            return (
                                <div className='casino__coin-flip__form-radio-btn-box' key={x} >
                                    <input type={'radio'} value={`flip_${x}`} id={`flip_${x}`} style={{}} name='flipOptions' />
                                    <label htmlFor={`flip_${x}`} className='casino__coin-flip__form-radio-btn-container' style={{ marginTop: '2px', marginBottom: '2px', marginLeft: '5px', marginRight: '5px' }}>
                                        <div style={{ textAlign: 'center', display: 'flex', flexFlow: 'row wrap', fontSize: '1rem', color: 'white' }}>
                                            <div>{x === 'Head' ? 'Live like YOLO' : 'Give into FOMO'}</div>
                                        </div>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>


                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', flexFlow: 'row wrap' }}>
                    <input type={"submit"} disabled={disableBtn} style={{ fontSize: '5rem', color: 'black', height: '100%', fontWeight: '700' }} className='font-montserrat casino__coin-flip__blue-submit-form-btn' value={'Flip Coin'} />
                    {project.data.fees && (
                        <div style={{
                            width: '100%',
                            textAlign: 'center',
                            color: 'black',
                            fontFamily: 'Share Tech',
                            fontSize: '1.2rem',
                            fontWeight: '700'
                        }} className='casino__coin-flip__project-fees'>
                            {project.data.fees}% fees on every bet
                        </div>
                    )}
                </div>
                <img src={CoinFlipIllustration} alt='' style={{width: '100%'}}/>
            </form>

        </div>
    )
}

const RecentGames = ({ recentGamesState }) => {
    const [recentGames, setRecentGames] = recentGamesState
    const { activeTokenState, project } = useContext(GameContext)
    const [activeToken] = activeTokenState
    useEffect(() => {
        async function getRecentTransactions() {
            try {
                let req = await fetch(`${config.apiURL}/get-recent-games`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        projectId: project.id,
                        tokenId: activeToken.tokenId,
                        gameId: 'coinflip'
                    })
                })
                let res = await req.json()
                if (res.error) return setRecentGames([])
                else return setRecentGames(res.slice(0, 15))
            } catch {
                return setRecentGames([])
            }
        }
        getRecentTransactions()
    }, [activeToken, project, setRecentGames])
    const explorer = 'https://solscan.io'
    return (
        <div className='casino__recent-games-container' style={{ margin: '1rem 20px' }}>
            {/* <div style={{ fontSize: '2rem', color: 'white', background: '#244c82', fontWeight: '700', borderRadius: '0px' }} className='font-montserrat casino__coin-flip__white-text-box-container' >Recent Games</div> */}
            <div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: '1.5rem', color: 'white', background: '#3b38ad', fontWeight: '700', borderRadius: '20px 20px 0px 0px' }} className='font-montserrat casino__coin-flip__white-text-box-container' >RECENT GAMES</div>
            <div className='casino__coin-flip__recent-transactions' style={{ overflow: 'scroll' }}>
                {recentGames && recentGames.length ? recentGames.map((x, i) => {
                    let color = 'green'
                    let tokenSymbolColor = '#b5f9b5'

                    if (!x.data.win) {
                        color = 'red'
                        tokenSymbolColor = '#ff9f9f'
                    }
                    return (
                        <div key={i} className='casino__recent-game font-montserrat'>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ border: '0px' }}
                            >
                                <TableCell component="th" scope="row" style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '95px', textAlign: 'center' }}>
                                    <div>
                                        <a style={{ textDecoration: 'none', fontSize: '1rem', color: 'rgb(133 185 255)' }} href={`${explorer}/account/${x.walletAddress}`} target={"_blank"} rel={"noreferrer"}>
                                            [{x.walletAddress.slice(0, 5)}...{x.walletAddress.slice(-2)}]
                                        </a>
                                    </div>
                                </TableCell>
                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '40px', textAlign: 'center' }}>
                                    <div className={`casino__recent-game__${x.data.win ? 'won' : 'lost'}-text`}>{x.data.win === 'push' ? 'Push' : x.data.win ? 'Won' : 'Lost'}</div>
                                </TableCell>

                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '70px', textAlign: 'center' }}>
                                    <div className={`casino__recent-game__amount-text-${x.data.win ? 'green' : 'red'}`}>{nFormatter(x.data.win === true ? x.data.prize : x.data.amount)} {activeToken.tokenSymbol}</div>
                                </TableCell>

                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '100px', textAlign: 'center' }}>
                                    <Timestamp relative date={new Date(x.playedAt)} autoUpdate style={{ fontSize: '1rem', color: 'white' }} />
                                </TableCell>
                            </TableRow>
                        </div>
                    )
                }) : recentGames && !recentGames.length ?
                    (<div style={{
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '1rem',
                        padding: '1rem',
                        textTransform: 'uppercase'
                    }}>
                        No games played for this token yet!
                    </div>)
                    : (<div style={{ minWidth: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80%', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
                            <img src={preloader} style={{ width: '80px', maxWidth: '60vw' }} alt='preloader' />
                        </div>
                    </div>)
                }
            </div>

        </div >
    )
}
