// import { clusterApiUrl } from "@solana/web3.js"

const config = {
    apiURL: 'https://economy-api.etakit.in',
    // apiURL: 'http://localhost:8080',
    clusterApiURL: 'https://hidden-warmhearted-pond.solana-mainnet.discover.quiknode.pro/6ddc84961813fb432e949914496786f060dfd43e/',
    domains: [
        {name: 'bonkasino', id: 'bonkkasino'},
        {name: 'bigbrain', id: 'bigbrain'}
    ]
}

export default config