import config from "../config";
import { setNewBalance, showAlert, sleep } from "./utility";

export const configureAndSendCurrentTransaction = async (
    transaction,
    connection,
    feePayer,
    signTransaction
) => {
    const blockHash = await connection.getLatestBlockhash();
    transaction.feePayer = feePayer;
    transaction.recentBlockhash = blockHash.blockhash;
    const signed = await signTransaction(transaction);
    const signature = await connection.sendRawTransaction(signed.serialize());
    await connection.confirmTransaction({
        blockhash: blockHash.blockhash,
        lastValidBlockHeight: blockHash.lastValidBlockHeight,
        signature
    });
    return signature;
};

export const coinFlip = async (event, setAlert, setDisableBtn, publicKey, activeToken, projectId, setActiveToken, setDisplaySignModal, setRecentGames, project) => {
    event.preventDefault()
    try {
        setDisableBtn(true)
        let payload = {}

        const multipliers = document.getElementsByName('multiplier');
        for (const multiplier of multipliers) {
            if (multiplier.checked && !payload.multiplier) {
                payload.multiplier = Number(multiplier.value.replace('multiplier_', ''))
                break;
            }
        }

        const amounts = document.getElementsByName('amount');
        for (const amount of amounts) {
            if (amount.checked && !payload.amount) {
                payload.amount = Number(amount.value.replace('amount_', ''))
                break;
            }
        }

        const flipChoices = document.getElementsByName('flipOptions');
        for (const flipChoice of flipChoices) {
            if (flipChoice.checked && !payload.flipChoice) {
                payload.flipChoice = flipChoice.value.replace('flip_', '')
                break;
            }
        }
        if (!payload.multiplier && !project.data.noCoinFlipMultiplier) {
            setDisableBtn(false)
            return showAlert(setAlert, 'You need to choose multiplier')
        }
        if (!payload.amount) {
            setDisableBtn(false)
            return showAlert(setAlert, 'You need to choose amount you want to bet')
        }
        if (!payload.flipChoice) {
            setDisableBtn(false)
            return showAlert(setAlert, 'You need to choose your flip choice')
        }
        payload.walletAddress = publicKey
        if (payload.amount > activeToken.balance) {
            setDisableBtn(false)
            return showAlert(setAlert, `Your balance is ${activeToken.balance}, you cannot bet more than that.`)
        }

        payload.signature = localStorage.getItem('signature_sign_in')
        payload.nonceId = localStorage.getItem('access_id')
        payload.projectId = projectId
        payload.tokenId = activeToken.tokenId
        const req = await fetch(`${config.apiURL}/flip-coin`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            })
        const res = await req.json()
        if (res.status === 'error') {
            if (res.data.nonceFail) {
                setDisableBtn(false)
                return setDisplaySignModal(true)
            } else {
                setDisableBtn(false)
                return showAlert(setAlert, res.message)
            }
        } else {
            var flipTail = [
                "fliptail900",
                "fliptail1260",
                "fliptail1620",
                "fliptail1980"
            ];
            var flipHead = [
                "fliphead1080",
                "fliphead1440",
                "fliphead1800",
                "fliphead2160"
            ]
            const getSpin = (spinArray) => {
                var spin = spinArray[Math.floor(Math.random() * spinArray.length)];
                return spin;
            }

            let flipChoice = ''
            if (!res.data.win) {
                flipChoice = (payload.flipChoice === 'Head' ? 'Tail' : 'Head')
            } else {
                flipChoice = payload.flipChoice
            }
            document.getElementById("casino__coin-flip__coin").className = ''
            setTimeout(() => {
                document.getElementById("casino__coin-flip__coin").classList.add(getSpin(flipChoice === 'Head' ? flipHead : flipTail));
            }, 300);

            setTimeout(() => {
                setDisableBtn(false)
                setActiveToken((prev) => {
                    prev.balance = res.data.newBalance
                    return prev
                })
                
                if (!res.data.win) {
                    return showAlert(setAlert, res.message)
                } else {
                    return showAlert(setAlert, res.message, 'success')
                }
            }, 3500);
        }
    } catch (err) {
        console.log(err)
        setDisableBtn(false)
        return showAlert(setAlert, err.message ? err.message : 'An unknown error occured')
    }
}

export const blackJack = (req, blackJackInfo, setBlackJackInfo, setAlert, setTokens, setActiveToken) => {
    try {
        if (req.data && req.data.newBalance) {
            setActiveToken((prev) => {
                if (prev.tokenId === req.data.tokenId) {
                    return { ...prev, balance: req.data.newBalance }
                }
                return prev
            })
            setTokens((prev) => {
                const token = prev.find(x => x.tokenId === req.data.tokenId)
                if (token) token.balance = req.data.newBalance
                return prev
            })
        }
        if (req.type === 'game-update') {
            setBlackJackInfo(req.data)
        }
        if (req.type === 'alert') {
            if (!req.status) showAlert(setAlert, req.message)
        }
    } catch (err) {
        console.log(err)
        showAlert(setAlert, err.message ? err.message : 'An unknown error occured')
    }
}

export const spinSlots = async (walletAddress, projectId, activeToken, setAlert, setActiveToken, setTokens, setDisplaySignModal, setDisableSlotBtn) => {
    setDisableSlotBtn(true)
    const amounts = document.getElementsByName('slot-machine-amount');
    var amountChosen;
    for (const amount of amounts) {
        console.log(amount.checked, amount.value)
        if (amount.checked && !amountChosen) {
            amountChosen = Number(amount.value.replace('amount_', ''))
            break;
        }
    }

    let signature = localStorage.getItem('signature_sign_in')
    let nonceId = localStorage.getItem('access_id')
    const spinReq = await fetch(`${config.apiURL}/slot-machine`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            walletAddress: walletAddress,
            projectId: projectId,
            signature: signature,
            nonceId: nonceId,
            tokenId: activeToken.tokenId,
            amount: amountChosen
        })
    })
    let spinReqJson = await spinReq.json()
    if (spinReqJson.status === 'error') {
        if (spinReqJson.data.nonceFail) {
            setDisableSlotBtn(false)
            return setDisplaySignModal(true)
        }
        setDisableSlotBtn(false)
        return showAlert(setAlert, spinReqJson.message)
    }
    const newPositions = spinReqJson.data.chosenSlot
    const arrayOfSlotItems = [
        { name: 'Banana', id: 'banana', position: 13 },
        { name: 'Bar', id: 'bar', position: 14 },
        { name: 'Cherry', id: 'cherry', position: 15 },
        { name: 'Clock', id: 'clock', position: 16 },
        { name: 'Coin', id: 'coin', position: 17 },
        { name: 'Crown', id: 'crown', position: 18 },
        { name: 'Gift', id: 'gift', position: 19 },
        { name: 'Sandclock', id: 'sandclock', position: 20 },
        { name: 'Seven', id: 'seven', position: 21 },
        { name: 'Star', id: 'star', position: 22 },
        { name: 'Trophy', id: 'trophy', position: 23 },
        { name: 'Watermelon', id: 'watermelon', position: 24 }
    ]
    const height = document.getElementById('casino__slot__slot-2').offsetHeight
    const elements = document.getElementsByClassName('casino__slot__slot-1')
    const newPosition1 = arrayOfSlotItems.find(x => x.id === newPositions[0])
    for (const x of elements) {
        x.setAttribute('style', `width: 100%;transition: all ease-in 0s;`);
    }
    await sleep(200)
    for (const x of elements) {
        x.setAttribute('style', `width: 100%;transition: all ease-in 2s;transform:translateY(-${(newPosition1.position * height) - newPosition1.position * 2}px);`);
    }
    const elements2 = document.getElementsByClassName('casino__slot__slot-2')
    const newPosition2 = arrayOfSlotItems.find(x => x.id === newPositions[1])
    for (const x of elements2) {
        x.setAttribute('style', `width: 100%;transition: all ease-in 0s;`);
    }
    await sleep(200)

    for (const x of elements2) {
        x.setAttribute('style', `width: 100%;transition: all ease-in 2s;transform:translateY(-${(newPosition2.position * height) - newPosition2.position * 2}px);`);
    }
    const elements3 = document.getElementsByClassName('casino__slot__slot-3')
    const newPosition3 = arrayOfSlotItems.find(x => x.id === newPositions[2])
    for (const x of elements3) {
        x.setAttribute('style', `width: 100%;transition: all ease-in 0s;`);
    }
    await sleep(200)
    for (const x of elements3) {
        x.setAttribute('style', `width: 100%;transition: all 0s ease-in 0s;`);
        x.setAttribute('style', `width: 100%;transition: all ease-in 2s;transform:translateY(-${(newPosition3.position * height) - newPosition3.position * 2}px);`);
    }
    await sleep(2200)
    if (spinReqJson.data.win) {
        setDisableSlotBtn(false)
        showAlert(setAlert, spinReqJson.message, 'success')
        setNewBalance(setActiveToken, setTokens, spinReqJson.data.newBalance, activeToken.tokenId)
    } else {
        setDisableSlotBtn(false)
        showAlert(setAlert, spinReqJson.message)
        setNewBalance(setActiveToken, setTokens, spinReqJson.data.newBalance, activeToken.tokenId)
    }
}


export const crashEnter = async (autoCashout, setAlert, setDisableBtn, publicKey, activeToken, projectId, setDisplaySignModal, setActiveToken, setTokens) => {
    try {
        setDisableBtn(true)
        let payload = {}


        const amounts = document.getElementsByName('crash-amount');
        for (const amount of amounts) {
            if (amount.checked && !payload.amount) {
                payload.amount = Number(amount.value.replace('amount_', ''))
                break;
            }
        }

        if (!payload.amount) {
            setDisableBtn(false)
            return showAlert(setAlert, 'You need to choose amount you want to bet')
        }
        payload.autoCashout = autoCashout
        payload.walletAddress = publicKey
        if (payload.amount > activeToken.balance) {
            setDisableBtn(false)
            return showAlert(setAlert, `Your balance is ${activeToken.balance}, you cannot bet more than that.`)
        }

        payload.signature = localStorage.getItem('signature_sign_in')
        payload.nonceId = localStorage.getItem('access_id')
        payload.projectId = projectId
        payload.tokenId = activeToken.tokenId
        const req = await fetch(`${config.apiURL}/crash-enter`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            })
        const res = await req.json()
        if (!res.status) {
            if (res.data.nonceFail) {
                setDisableBtn(false)
                return setDisplaySignModal(true)
            } else {
                setDisableBtn(false)
                return showAlert(setAlert, res.message)
            }
        } else {
            if (res.data.newBalance) {
                setNewBalance(setActiveToken, setTokens, res.data.newBalance, activeToken.tokenId)
            }
        }
    } catch (err) {
        return showAlert(setAlert, err.message ? err.message : 'An unknown error occured')
    }
}


export const cashoutCrash = async (setAlert, setDisableBtn, publicKey, activeToken, projectId, setDisplaySignModal) => {
    try {
        setDisableBtn(true)
        let payload = {}

        payload.walletAddress = publicKey
        payload.signature = localStorage.getItem('signature_sign_in')
        payload.nonceId = localStorage.getItem('access_id')
        payload.projectId = projectId
        payload.tokenId = activeToken.tokenId
        const req = await fetch(`${config.apiURL}/cashout-crash`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            })
        const res = await req.json()
        if (!res.status) {
            if (res.data.nonceFail) {
                setDisableBtn(false)
                return setDisplaySignModal(true)
            } else {
                setDisableBtn(false)
                return showAlert(setAlert, res.message)
            }
        }
    } catch (err) {
        return showAlert(setAlert, err.message ? err.message : 'An unknown error occured')

    }
}


export const buyPowerballTickets = async (setAlert, setDisableBtn, publicKey, activeToken, projectId, setDisplaySignModal, activeLottery, numberOfTickets, setActiveToken, setTokens, quickpick, setDisplayBuyTicketsModal, displayBuyTicketsModal) => {
    try {
        let checkedNumbers;
        let checkedPowerballNumber;
        if (!quickpick && !displayBuyTicketsModal) {
            return setDisplayBuyTicketsModal(true)
        } else if(!quickpick && displayBuyTicketsModal) {
            const elements = document.getElementsByClassName('casino__powerball__main-numbers-input-radio-btn')
            checkedNumbers = []
            for (var i = 0; i < elements.length; i++) {
                if (elements[i].checked) {
                    checkedNumbers.push(elements[i].id.replace('casino__powerball__main-numbers-input-radio-btn-', ''))
                }
            }
            if (checkedNumbers.length !== 6) {
                setDisableBtn(false)
                return showAlert(setAlert, 'You need to select six main numbers')
            }

            const powerballElements = document.getElementsByClassName('casino__powerball__powerball-number-input-radio-btn')
            checkedPowerballNumber = []
            for (var i = 0; i < powerballElements.length; i++) {
                if (powerballElements[i].checked) {
                    checkedPowerballNumber.push(powerballElements[i].id.replace('casino__powerball__powerball-number-input-radio-btn-', ''))
                }
            }
            if (checkedPowerballNumber.length !== 1) {
                setDisableBtn(false)
                return showAlert(setAlert, 'You need to select one powerball number')
            }
            checkedNumbers = checkedNumbers.map(x => Number(x))
            checkedPowerballNumber = Number(checkedPowerballNumber[0])
        }
        setDisableBtn(true)
        let payload = {}

        payload.walletAddress = publicKey
        payload.signature = localStorage.getItem('signature_sign_in')
        payload.nonceId = localStorage.getItem('access_id')
        payload.projectId = projectId
        payload.tokenId = activeToken.tokenId
        payload.roundId = activeLottery.liveGameId
        payload.numberOfTickets = numberOfTickets
        if (checkedNumbers && checkedPowerballNumber) {
            payload.choosenValues = { mainNumbers: checkedNumbers, powerballNumber: checkedPowerballNumber }
        }
        const req = await fetch(`${config.apiURL}/buy-powerball-tickets`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            })
        const res = await req.json()
        if (!res.status) {
            if (res.data.nonceFail) {
                setDisableBtn(false)
                setDisplayBuyTicketsModal(false)
                return setDisplaySignModal(true)
            } else {
                setDisableBtn(false)
                return showAlert(setAlert, res.message)
            }
        } else {
            setDisableBtn(false)
            if (res.data.newBalance) {
                setDisplayBuyTicketsModal(false)
                setNewBalance(setActiveToken, setTokens, res.data.newBalance, activeToken.tokenId)
            }
            return showAlert(setAlert, res.message, 'success')
        }
    } catch (err) {
        return showAlert(setAlert, err.message ? err.message : 'An unknown error occured')

    }
}
