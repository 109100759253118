import config from "../config";
import { setNewBalance, showAlert } from "./utility";

export const raceEnter = async (numberOfSpots, setAlert, setDisableBtn, publicKey, activeToken, projectId, setDisplaySignModal, setActiveToken, setTokens) => {
    try {
        setDisableBtn(true)
        let payload = {}
        payload.amount = 0.1*numberOfSpots
        const sprites = document.getElementsByName('game__racing__sprite__choose');
        for (const sprite of sprites) {
            if (sprite.checked && !payload.sprite) {
                console.log(sprite.id)
                payload.sprite = (sprite.id.replace('game__racing__tracks__sprite-', ''))
                break;
            }
        }

        if (!payload.sprite) {
            setDisableBtn(false)
            return showAlert(setAlert, 'You need to fill every info before placing a bet')
        }
        payload.walletAddress = publicKey
        if (payload.amount > activeToken.balance) {
            setDisableBtn(false)
            return showAlert(setAlert, `Your balance is ${activeToken.balance}, you cannot bet more than that.`)
        }

        payload.signature = localStorage.getItem('signature_sign_in')
        payload.nonceId = localStorage.getItem('access_id')
        payload.projectId = projectId
        payload.tokenId = activeToken.tokenId
        payload.numberOfSpots = numberOfSpots
        const req = await fetch(`${config.apiURL}/racing-enter`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            })
        const res = await req.json()
        if (!res.status) {
            if (res.data.nonceFail) {
                setDisableBtn(false)
                return setDisplaySignModal(true)
            } else {
                setDisableBtn(false)
                return showAlert(setAlert, res.message)
            }
        } else {
            if (res.data.newBalance) {
                setNewBalance(setActiveToken, setTokens, res.data.newBalance, activeToken.tokenId)
            }
            setDisableBtn(false)
        }
    } catch (err) {
        console.log(err)
        return showAlert(setAlert, err.message ? err.message : 'An unknown error occured')
    }
}
