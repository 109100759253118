import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { spinSlots } from '../../../javascript/casino'
import { GameContext } from '../../Game'
import {useWallet} from '@solana/wallet-adapter-react'
import config from '../../../config'
import Timestamp from 'react-timestamp'
import preloader from './../../../assets/img/utility/preloader.gif'

export default function Slot() {
  const { activeTokenState , project, alertState, setTokens, displaySignModalState} = useContext(GameContext)
  const [,setDisplaySignModal] = displaySignModalState
  const [activeToken, setActiveToken] = activeTokenState
  const [, setAlert] = alertState
  const {publicKey} = useWallet()
  const [disableSlotBtn, setDisableSlotBtn] = useState(false)
  const [displaySlotTableModal, setDisplaySlotTableModal] = useState(false)
  const [recentGames, setRecentGames] = useState()
  const amounts = [0.05, 0.1, 0.5, 1, 5, 10]
  const arrayOfSlotItems = [
    { name: 'Banana', id: 'banana', position: 13 },
    { name: 'Bar', id: 'bar', position: 14 },
    { name: 'Cherry', id: 'cherry', position: 15 },
    { name: 'Clock', id: 'clock', position: 16 },
    { name: 'Coin', id: 'coin', position: 17 },
    { name: 'Crown', id: 'crown', position: 18 },
    { name: 'Gift', id: 'gift', position: 19 },
    { name: 'Sandclock', id: 'sandclock', position: 20 },
    { name: 'Seven', id: 'seven', position: 21 },
    { name: 'Star', id: 'star', position: 22 },
    { name: 'Trophy', id: 'trophy', position: 23 },
    { name: 'Watermelon', id: 'watermelon', position: 24 }
  ]
  return (
    <>
      <SlotsTableModal displayModal={displaySlotTableModal} setDisplayModal={setDisplaySlotTableModal} />
      <div style={{ display: 'flex', flexFlow: 'column wrap', alignItems: 'center', justifyContent: 'center' }}>
        <div className='casino__slot__container'>
          <div className='casino__slot__reels-container'>
            <div className='casino__slot__slot-reel'>
              <img className='casino__slot__slot-1' alt={activeToken.tokenSymbol}  src={activeToken.tokenLogo} style={{ width: '100%', transition: 'all ease-in 0.4s' }} />
              {arrayOfSlotItems.map((x, i) => {
                const icon = require(`./../../../assets/img/casino/SlotIcons/${x.id}.png`)
                return (
                  <img key={i} alt={x} className='casino__slot__slot-1' src={icon} style={{ width: '100%', transition: 'all ease-in 0.4s' }} />
                )
              })}
              {arrayOfSlotItems.map((x, i) => {
                const icon = require(`./../../../assets/img/casino/SlotIcons/${x.id}.png`)
                return (
                  <img key={i} alt={x} className='casino__slot__slot-1' src={icon} style={{ width: '100%', transition: 'all ease-in 0.4s' }} />
                )
              })}
            </div>
            <div className='casino__slot__slot-reel' id='casino__slot__slot-2'>
              <img className='casino__slot__slot-1'  alt={activeToken.tokenSymbol}src={activeToken.tokenLogo} style={{ width: '100%', transition: 'all ease-in 0.4s' }} />

              {arrayOfSlotItems.map((x, i) => {
                const icon = require(`./../../../assets/img/casino/SlotIcons/${x.id}.png`)
                return (
                  <img key={i} alt={x} className='casino__slot__slot-2' src={icon} style={{ width: '100%', transition: 'all ease-in 0.4s' }} />
                )
              })}
              {arrayOfSlotItems.map((x, i) => {
                const icon = require(`./../../../assets/img/casino/SlotIcons/${x.id}.png`)
                return (
                  <img key={i} alt={x} className='casino__slot__slot-2' src={icon} style={{ width: '100%', transition: 'all ease-in 0.4s' }} />
                )
              })}
            </div>
            <div className='casino__slot__slot-reel'>
              <img className='casino__slot__slot-1' alt={activeToken.tokenSymbol} src={activeToken.tokenLogo} style={{ width: '100%', transition: 'all ease-in 0.4s' }} />
              {arrayOfSlotItems.map((x, i) => {
                const icon = require(`./../../../assets/img/casino/SlotIcons/${x.id}.png`)
                return (
                  <img key={i} alt={x} className='casino__slot__slot-3' src={icon} style={{ width: '100%', transition: 'all ease-in 0.4s' }} />
                )
              })}
              {arrayOfSlotItems.map((x, i) => {
                const icon = require(`./../../../assets/img/casino/SlotIcons/${x.id}.png`)
                return (
                  <img key={i} alt={x} className='casino__slot__slot-3' src={icon} style={{ width: '100%', transition: 'all ease-in 0.4s' }} />
                )
              })}
            </div>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column wrap'
          }}>
            <div style={{ fontSize: '1.2rem', color: 'black', fontWeight: '700', marginTop: '10px', width: '80%' }} className='font-montserrat casino__coin-flip__white-text-box-container' >Amount</div>
            <div style={{ display: 'flex', flexFlow: 'row wrap', margin: 'auto', justifyContent: 'center' }}>
              {amounts.map(x => {
                let tokenValue = 1
                if (activeToken.data && activeToken.data.tokenValue) tokenValue = activeToken.data.tokenValue
                return (
                  <div className='casino__blackjack__form-radio-btn-box' key={`amount_${x}`}>
                    <input type={'radio'} value={`amount_${x}`} id={`amount_${x}`} name='slot-machine-amount' />
                    <label htmlFor={`amount_${x}`} className='casino__blackjack__form-radio-btn-container' style={{ marginTop: '2px', marginBottom: '2px', marginLeft: '5px', marginRight: '5px' }}>
                      <div style={{ textAlign: 'center', display: 'flex', flexFlow: 'row wrap', fontSize: '1rem', color: 'white' }}>
                        <div>{x * tokenValue} {activeToken.tokenSymbol}</div>
                      </div>
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button style={{ margin: '0.6rem 1rem 0px 0px' }} className='casino__btn-purple' onClick={() => setDisplaySlotTableModal(true)}><i className='bx bx-table'></i></button>
            <button className='casino__btn-purple' onClick={() => spinSlots(publicKey, project.id, activeToken, setAlert, setActiveToken, setTokens, setDisplaySignModal, setDisableSlotBtn)} disabled={disableSlotBtn}>Spin</button>
          </div>
        </div>
        <RecentGames recentGamesState={[recentGames, setRecentGames]}/>
      </div>
    </>
  )
}


const SlotsTableModal = ({ displayModal, setDisplayModal }) => {
  const rewards = [
    {itemsChosen: ['seven', 'seven', 'seven'], winRate: 20},
    {itemsChosen: ['bar', 'bar', 'bar'],  winRate: 10},
    {itemsChosen: ['crown', 'crown', 'crown'],  winRate: 8},
    {itemsChosen: ['trophy', 'trophy', 'trophy'], winRate: 7},
    {itemsChosen: ['coin', 'coin', 'coin'],  winRate: 5},
    {itemsChosen: ['any three'], winRate: 4},
    {itemsChosen: ['any two', 'seven'], winRate: 3.5},
    {itemsChosen: ['any two', 'bar'], winRate: 3},
    {itemsChosen: ['any two'], winRate: 2.5}
  ]

  return (
    <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
      <div className='modal-bg' onClick={() => setDisplayModal(false)}></div>
      <div className='modal-container'>
        <div className='modal font-montserrat' style={{ color: 'white', padding: '1.5rem', justifyContent: 'left', overflowY: 'scroll' }}>
          <div style={{ display: 'flex', marginLeft: '90%', marginTop: '10px' }}>
            <div className='modal-close-btn' onClick={() => setDisplayModal(false)}>
              <div style={{ lineHeight: '1.2em', color: 'black' }}>✖</div>
            </div>
          </div>

          <TableContainer component={Paper} style={{ backgroundColor: 'transparent' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: '1.2rem', color: 'white', fontWeight: '500' }}>Slot Outcome</TableCell>
                  <TableCell align="right" style={{ fontSize: '1.2rem', color: 'white', fontWeight: '500' }}>Reward Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rewards.map((x, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ border: '0px' }}
                    >
                      <TableCell component="th" scope="row" style={{ border: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.5rem' }}>
                        {x.itemsChosen.map((x, i) => {
                          let img;
                          if (!x.startsWith('any')) img = require(`./../../../assets/img/casino/SlotIcons/${x}.png`)
                          if (img) {
                            return (<img src={img}  key={i}alt={x} style={{ height: '4rem' }} />)
                          } else {
                            return (
                              <div key={i} style={{ color: 'white',  fontSize: '1rem', textTransform: 'uppercase', background: '#02081b', padding: '0.5rem', borderRadius: '10px' }}>
                                {x}
                              </div>
                            )
                          }
                        })}
                      </TableCell>
                      <TableCell align="right" style={{ padding: '0px', textAlign: 'center', border: '0px', fontSize: '1.2rem', color: 'white', fontWeight: '500' }}>{x.winRate}x</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>

  );
}


const RecentGames = ({ recentGamesState }) => {
  const [recentGames, setRecentGames] = recentGamesState
  const { activeTokenState, project } = useContext(GameContext)
  const [activeToken] = activeTokenState
  useEffect(() => {
      async function getRecentTransactions() {
          try {
              let req = await fetch(`${config.apiURL}/get-recent-games`, {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                      projectId: project.id,
                      tokenId: activeToken.tokenId,
                      gameId: 'slotmachine'
                  })
              })
              let res = await req.json()
              if (res.error) return setRecentGames([])
              else return setRecentGames(res)
          } catch {
              return setRecentGames([])
          }
      }
      getRecentTransactions()
  }, [activeToken, project, setRecentGames])
  const explorer = 'https://solscan.io'
  return (
      <div className='casino__recent-games-container' style={{ margin: '1rem 20px' }}>
          {/* <div style={{ fontSize: '2rem', color: 'white', background: '#244c82', fontWeight: '700', borderRadius: '0px' }} className='font-montserrat casino__coin-flip__white-text-box-container' >Recent Games</div> */}
          <div style={{ paddingLeft: '40px', paddingRight: '40px', fontSize: '1.5rem', color: 'white', background: '#3b38ad', fontWeight: '700', borderRadius: '20px 20px 0px 0px' }} className='font-montserrat casino__coin-flip__white-text-box-container' >RECENT GAMES</div>
          <div className='casino__recent-games-subcontainer'>
              {recentGames && recentGames.length ? recentGames.map((x, i) => {
                  let color = 'green'
                  let tokenSymbolColor = '#b5f9b5'

                  if (!x.data.win) {
                      color = 'red'
                      tokenSymbolColor = '#ff9f9f'
                  }
                  if (x.data.win === 'push') {
                      color = '#0072ff'
                      tokenSymbolColor = 'rgb(159 207 255)'
                  }
                  return (
                      <div key={i} className='casino__recent-game font-montserrat'>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ border: '0px' }}
                            >
                                <TableCell component="th" scope="row" style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '95px', textAlign: 'center' }}>
                                    <div>
                                        <a style={{ textDecoration: 'none', fontSize: '1rem', color: 'rgb(133 185 255)' }} href={`${explorer}/account/${x.walletAddress}`} target={"_blank"} rel={"noreferrer"}>
                                            [{x.walletAddress.slice(0, 5)}...{x.walletAddress.slice(-2)}]
                                        </a>
                                    </div>
                                </TableCell>
                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '40px', textAlign: 'center' }}>
                                    <div style={{ color: color, fontWeight: '700', textTransform: 'uppercase' }}>{x.data.win === 'push' ? 'Push' : x.data.win ? 'Won' : 'Lost'}</div>
                                </TableCell>

                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '70px', textAlign: 'center' }}>
                                    <div style={{ color: tokenSymbolColor }}>{x.data.win === true ? x.data.prize : x.data.amount} {activeToken.tokenSymbol}</div>
                                </TableCell>

                                <TableCell style={{ paddingTop: '0px', paddingBottom: '0px', minWidth: '100px', textAlign: 'center' }}>
                                    <Timestamp relative date={new Date(x.playedAt)} autoUpdate style={{ fontSize: '1rem', color: 'white' }} />
                                </TableCell>
                            </TableRow>
                      </div>
                  )
              }) : recentGames && !recentGames.length ?
                  (<div style={{
                      color: 'white',
                      textAlign: 'center',
                      fontSize: '1rem',
                      padding: '1rem',
                      textTransform: 'uppercase'
                  }}>
                      No games played for this token yet!
                  </div>)
                  : (<div style={{ minWidth: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80%', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
                          <img src={preloader} style={{ width: '80px', maxWidth: '60vw' }} alt='preloader' />
                      </div>
                  </div>)
              }
          </div>

      </div >
  )
}
