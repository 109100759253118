import React, { createRef, useContext, useEffect, useRef, useState } from 'react'
import { IconHistory } from '@tabler/icons'
import 'chart.js/auto'
import { Line } from 'react-chartjs-2'
import { useWallet } from '@solana/wallet-adapter-react'
import 'chartjs-adapter-moment'
import preloader from './../../../assets/img/utility/preloader.gif'
import { GameContext } from '../../Game'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { nFormatter, setNewBalance, showAlert } from '../../../javascript/utility'
import { cashoutCrash, crashEnter } from '../../../javascript/casino'
import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import TokenLogo from '../../../components/utility/TokenLogo'
import config from '../../../config'
export default function Crash() {

    const [autoCashoutError, setAutoCashoutError] = useState('')
    const [autoCashout, setAutoCashout] = useState()
    const [betStatus, setBetStatus] = useState()
    const [entries, setEntries] = useState([])

    const recentBets = [1.6, 5.5, 2.4, 21.3, 1.2, 0.5, 1.6, 1.8, 10, 4, 6, 25]

    const { activeTokenState, socket, alertState, project, displaySignModalState, setTokens } = useContext(GameContext)
    const [, setDisplaySignModal] = displaySignModalState
    const [, setAlert] = alertState
    const [activeToken, setActiveToken] = activeTokenState
    const { publicKey } = useWallet()
    const [disableBtn, setDisableBtn] = useState(false)
    const getActiveTokenId = () => {
        return activeToken.tokenId
    }
    useEffect(() => {
        async function socketCrashHandler(data) {
            try {
                if (data.type === 'game-update') {
                    setBetStatus(data.data)
                    if (data.data.entries) {
                        const userEntry = data.data.entries.find(x => x.projectId === project.id && x.walletAddress === publicKey.toString() && x.tokenId === activeToken.tokenId
                        )
                        if (userEntry && userEntry.newBalance) {
                            await setNewBalance(setActiveToken, setTokens, userEntry.newBalance, userEntry.tokenId, true)
                        }
                        setEntries(data.data.entries)
                    } else {
                        setEntries([])
                    }
                } else if (data.type === 'crash-enter') {
                    if (data.status) {
                        await showAlert(setAlert, data.message, 'success')
                    } else {
                        await showAlert(setAlert, data.message, 'error')
                    }
                }
            } catch (err) {
                console.log(err)
            }

        }
        socket.on('crash', socketCrashHandler)

        return () => socket.off('crash', socketCrashHandler);
    }, [socket, activeToken])

    const crashedAmount = () => {
        let prize = parseFloat((betStatus.nextCrashRate * entries.find(x => x.projectId === project.id && x.walletAddress === publicKey.toString() && x.tokenId === activeToken.tokenId).amount) / (activeToken.data && activeToken.data.decimals ? activeToken.data.decimals : LAMPORTS_PER_SOL).toFixed(10))
        if (prize) {
            return (<>{prize} <TokenLogo activeToken={activeToken} style={{ margin: '0px 0.4rem' }} /></>)
        } else {
            return (<></>)
        }
    }
    const amounts = [0.1, 0.5, 1, 5, 10]
    const handleAutoCashoutChange = (event) => {
        const value = event.target.value
        if (value) {
            if (value < 0.1) {
                setAutoCashout(value)
                return setAutoCashoutError(`You cannot auto cashout less than 0.1x`)
            } else if (value > 10) {
                setAutoCashout(value)
                return setAutoCashoutError(`You cannot auto cashout greater than 10x`)
            } else {
                setAutoCashoutError()
                setAutoCashout(value)
            }
        } else {
            setAutoCashoutError()
            setAutoCashout()
        }
    }
    if (!betStatus) {
        return (
            <div style={{ minWidth: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80%', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
                    <img src={preloader} style={{ width: '250px', maxWidth: '60vw' }} alt='preloader' />
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', height: 'fit-content', padding: '1rem' }}>
                <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    <RecentGames />
                    <div className='casino__crash__container' style={{ position: 'relative', padding: '0px' }}>
                        <div className='casino__crash__chart-text-title' style={{ color: `${betStatus.isCrashed ? '#cb4646c7' : 'white'}` }}>
                            <div className='casino__crash__chart-text'>{!betStatus.nextCrashRate ? 'Next round in' : betStatus.isCrashed ? 'Crashed' : 'Current Multiplier'}</div>
                            {!betStatus.nextCrashRate ? (isNaN(Math.round((betStatus.nextCrash - Date.now()) / 1000)) ? '' : Math.round((betStatus.nextCrash - Date.now()) / 1000)) : `${betStatus.nextCrashRate.toFixed(2)}x`}
                        </div>
                        <div>
                            {project.data.crash ? (<><CrashCanvas betStatus={betStatus} /></>) : (<Line style={{ height: '300px', padding: '1rem' }} data={{
                                labels: [0, 9],
                                datasets: [
                                    {
                                        data: [0, !betStatus.nextCrashRate ? 0 : betStatus.nextCrashRate.toFixed(2)],
                                        label: "Africa",
                                        borderColor: "#3e95cd",
                                        fill: false,
                                    },
                                    {
                                        data: [3],
                                        label: "Africa",
                                        borderColor: "transparent",
                                        fill: true,
                                    },

                                ]
                            }}
                                options={{
                                    responsive: true,
                                    elements: {
                                        point: {
                                            radius: 5
                                        },
                                        line: {
                                            tension: 0.2
                                        }
                                    },
                                    curveType: 'function',
                                    maintainAspectRatio: false,
                                    plugins: {
                                        tooltip: {
                                            enabled: false
                                        },
                                        legend: false // Hide legend
                                    },
                                    scales: {
                                        x: {
                                            display: false // Hide Y axis labels
                                        },
                                        y: {
                                            ticks: {
                                                color: 'white',
                                                font: {
                                                    size: 20,
                                                    family: 'Signika Negative'
                                                },
                                                callback: function (value, index, ticks) {
                                                    return value + 'x';
                                                }
                                            }
                                        }
                                    },
                                    title: {
                                        display: false
                                    }
                                }} />)}
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    <div className='casino__crash__container'>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexFlow: 'column wrap',
                            padding: '1rem 0px'
                        }}>
                            <div style={{ fontSize: '1.2rem', color: 'black', fontWeight: '700', marginTop: '10px', width: '80%' }} className='font-signika casino__crash__white-text-box-container' >Bet Amount</div>
                            <div style={{ display: 'flex', flexFlow: 'row wrap', margin: 'auto', justifyContent: 'center' }}>
                                {(activeToken.data.amountOptions ? activeToken.data.amountOptions : amounts).map(x => {
                                    let tokenValue = 1
                                    if (activeToken.data && activeToken.data.tokenValue) tokenValue = activeToken.data.tokenValue
                                    return (
                                        <div className='casino__blackjack__form-radio-btn-box' key={`amount_${x}`}>
                                            <input type={'radio'} value={`amount_${x}`} id={`amount_${x}`} name='crash-amount' />
                                            <label htmlFor={`amount_${x}`} className='casino__blackjack__form-radio-btn-container' style={{ marginTop: '2px', marginBottom: '2px', marginLeft: '5px', marginRight: '5px' }}>
                                                <div style={{ textAlign: 'center', display: 'flex', flexFlow: 'row wrap', fontSize: '1rem', color: 'white' }}>
                                                    <div>{x * tokenValue} {activeToken.tokenSymbol}</div>
                                                </div>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='flex-row flex-wrap align-center' style={{ width: '80%' }}>
                                <div style={{ fontSize: '1.2rem', color: 'black', fontWeight: '700', marginTop: '10px', padding: '0.2rem 1rem' }} className='font-signika casino__crash__white-text-box-container' >Auto Cashout</div>
                                <input type={'number'} pattern={/^[1-9][0-9]*$/} min={0} max={10} className='casino__crash__auto-cashout-input' value={autoCashout} onChange={(event) => handleAutoCashoutChange(event)} />
                                {autoCashoutError && <div className='font-signika' style={{ fontSize: '0.8rem', color: 'red', width: '100%' }}>{autoCashoutError}</div>}
                            </div>
                            {entries &&
                                entries.find(x => x.projectId === project.id && x.walletAddress === publicKey.toString() && x.tokenId === activeToken.tokenId) ?
                                (entries.find(x => x.projectId === project.id && x.walletAddress === publicKey.toString() && x.tokenId === activeToken.tokenId).prizeClaimed ? (<><button className='crash__casino__button' disabled={true}>
                                    Cashed out {parseFloat((entries.find(x => x.projectId === project.id && x.walletAddress === publicKey.toString() && x.tokenId === activeToken.tokenId).prize / (activeToken.data && activeToken.data.decimals ? activeToken.data.decimals : LAMPORTS_PER_SOL).toFixed(10)).toFixed(10))} <TokenLogo activeToken={activeToken} style={{ margin: '0px 0.4rem' }} />
                                </button></>) : <><button className='crash__casino__button' onClick={() => cashoutCrash(setAlert, setDisableBtn, publicKey, activeToken, project.id, setDisplaySignModal)}
                                    disabled={!betStatus.nextCrashRate ? true : betStatus.isCrashed ? true ? disableBtn : true : false}
                                >
                                    Cash Out {crashedAmount()}
                                </button></>) : (<button className='crash__casino__button' onClick={() => crashEnter(autoCashout, setAlert, setDisableBtn, publicKey, activeToken, project.id, setDisplaySignModal, setActiveToken, setTokens)} disabled={!betStatus.nextCrashRate ? false : betStatus.isCrashed ? true ? disableBtn : true : true}>
                                    Place Bet
                                </button>)}
                            <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row wrap' }}>
                                {project.data.fees && (
                                    <div style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        color: 'black',
                                        fontFamily: 'Share Tech',
                                        fontSize: '1.2rem',
                                        color: 'white'
                                    }} className='casino__coin-flip__project-fees'>
                                        {project.data.fees}% fees on every bet
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>

                    <div className='casino__crash__container' style={{ height: '-webkit-fill-available', padding: '1rem 0px' }}>
                        <div className='flex-row flex-wrap align-center justify-center'>

                            <div style={{ fontSize: '1.2rem', color: 'black', fontWeight: '700', marginTop: '10px', padding: '0.2rem 1rem', width: '80%' }} className='font-signika casino__crash__white-text-box-container' >Entries</div>
                            {!entries.length || (entries.length && !entries.find(x => x.projectId === project.id && x.tokenId === activeToken.tokenId)) ? (<div style={{
                                fontSize: '1rem',
                                color: 'white',
                                textTransform: 'uppercase',
                                padding: '0px 1rem'
                            }}>There are no entries for this round yet</div>) : (
                                <TableContainer component={Paper} style={{ backgroundColor: '#5e45ffb3', width: '80%', padding: '0.2rem 1rem' }}>
                                    <Table>
                                        <TableBody>
                                            {entries.filter(x => x.projectId === project.id && x.tokenId === activeToken.tokenId).map((x, i) => {
                                                return (
                                                    <TableRow
                                                        key={i}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ border: '0px' }}
                                                    >
                                                        <TableCell component="th" scope="row" style={{ padding: '0px', textAlign: 'center', border: '0px', fontSize: '0.8rem', color: 'white', fontWeight: '500', padding: '0.1rem 0px', width: '200px' }}>
                                                            {x.walletAddress.slice(0, 3)}...{x.walletAddress.slice(x.walletAddress.length - 3, x.walletAddress.length)}
                                                        </TableCell>
                                                        <TableCell align="right" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'row wrap', padding: '0px', textAlign: 'center', border: '0px', fontSize: '0.8rem', color: 'white', fontWeight: '500', padding: '0.1rem 0px', width: '200px' }}>{nFormatter(Number((parseFloat(x.amount / (activeToken?.data?.decimals||LAMPORTS_PER_SOL)).toFixed(10))))} <TokenLogo activeToken={activeToken} style={{ margin: '0px 0.4rem' }} /></TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const CrashCanvas = ({ betStatus }) => {
    const vidRef = useRef(null);
    const { project } = useContext(GameContext)
    const handlePauseVideo = () => {
        vidRef.current.pause();
    }
    useEffect(() => {
        if (!betStatus.nextCrashRate) {
            vidRef.current.pause();
        } else {
            if (betStatus.isCrashed) {
                vidRef.current.pause();
            } else {
                vidRef.current.play();
            }
        }
    }, [betStatus])
    const video = require(`./../../../assets/img/custom/crash/${project.id}/background.mp4`)
    return (
        <div>
            <video ref={vidRef} style={{ width: '100%' }}>
                <source src={video} type="video/mp4" style={{ width: '100%' }} />
            </video>

            {betStatus.nextCrashRate ? (<img src={betStatus.isCrashed ? 'https://media.discordapp.net/attachments/913809515441422497/1070417129607802890/ezgif.com-gif-maker.gif' : 'https://media.discordapp.net/attachments/913809515441422497/1070410900625948773/running.gif'} style={{ width: '100%', position: 'absolute', left: '0', top: '0' }} />) : ''}
        </div >
    )
}



const RecentGames = ({ }) => {
    const [recentGames, setRecentGames] = useState([])
    const { activeTokenState, project } = useContext(GameContext)
    const [activeToken] = activeTokenState
    useEffect(() => {
        async function getRecentTransactions() {
            try {
                let req = await fetch(`${config.apiURL}/get-recent-games`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        projectId: 'none',
                        tokenId: 'none',
                        gameId: 'crash'
                    })
                })
                let res = await req.json()
                if (res.error) return setRecentGames([])
                else return setRecentGames(res)
            } catch {
                return setRecentGames([])
            }
        }
        getRecentTransactions()
    }, [activeToken, project, setRecentGames,])
    return (
        <div className='casino__crash__container'>
            <div className='flex-row align-center overflow-hidden' style={{ margin: '0px 0.2rem' }}>
                <div className={`font-signika casino__crash__recent-bet`}>
                    <IconHistory size={'1.3rem'}
                        color="white"
                        stroke={3}
                        strokeLinejoin="miter"
                    />
                </div>
                {recentGames.map((x, i) => {
                    const rate = x.data.crashedNumber
                    return (<div key={i} className={`font-signika casino__crash__recent-bet casino__crash__recent-bet-${rate < 1.5 ? 'red' : rate < 2 ? 'yellow' : rate < 4 ? 'green' : 'blue'}`}>{rate.toFixed(2)}x</div>)
                })}
            </div>
        </div>
    )
}