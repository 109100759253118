import React, { useContext } from 'react'
import { Route, Routes } from 'react-router'
import { Link } from 'react-router-dom'
import CoinFlip from './coinFlip/CoinFlip'
import './../../css/casino.css'
import BlackJack from './blackjack/BlackJack.js'
import Slot from './slot/Slot'
import { GameContext } from '../Game'
import Crash from './crash/Crash'
import Powerball from './powerball/PowerBall.js'
import ReactShadow from 'react-shadow'

const casinoGames = [
  { name: 'Coin Flip', route: 'coinflip', element: <CoinFlip /> },
  { name: 'BlackJack', route: 'blackjack', element: <BlackJack /> },
  { name: 'Slot Machine', route: 'slot', element: <Slot /> },
  { name: 'Crash', route: 'crash', element: <Crash /> },
  { name: 'YOLOLottery', route: 'yololottery', element: <Powerball /> }
]

export default function Casino() {
  const { project } = useContext(GameContext)
  return (
    <Routes>
      <Route path='/' element={<CasinoRouter />} />

      {casinoGames.map((x, i) => {
        let included = true
        if (project.data.includedGames) {
          if (!project.data.includedGames.includes(x.route)) {
            included = false
          }
        }
        if (project.data.notIncludedGames) {
          if (project.data.notIncludedGames.includes(x.route)) {
            included = false
          }
        }
        console.log(included)
        if (included) {
          return (
            <Route path={`/${x.route}`} element={x.element} key={i} />
          )
        }
      })}

    </Routes>
  )
}

const CasinoRouter = () => {
  return (
    <div className='game-list'>
      <RouterImages />
    </div>
  )
}

const RouterImages = () => {
  const { project } = useContext(GameContext)
  return (
    casinoGames.map((x, i) => {
      let icon;
      try {
        icon = require(`./../../assets/img/casino/CasinoRouterIcons/${x.route}.gif`)
      } catch (err) {
        console.log(err)
        try {
          icon = require(`./../../assets/img/casino/CasinoRouterIcons/${x.route}.png`)
        } catch (err2) {
          console.log(err2)
        }
      }

      let included = true
      if (project.data.includedGames) {
        if (!project.data.includedGames.includes(x.route)) {
          included = false
        }
      }
      if (project.data.notIncludedGames) {
        if (project.data.notIncludedGames.includes(x.route)) {
          included = false
        }
      }
      if (included) {
        return (
          <div style={{ margin: '1.3rem' }} key={i}>
            <Link to={`/casino/${project.id}/casino/${x.route}`} style={{ textDecoration: 'none' }}>
              <div className='game-list__game-container' id={`game-list__game-container__${x.route}`}>
                <img src={icon} alt={x.name} id={`game-list__game-container__${x.route}-img`} />
                <div className='game-list__game-container-text font-share-tech' id={`game-list__game-container__${x.route}-name`}>
                  {x.name}
                </div>
              </div>
            </Link>
          </div>
        )
      }
    })
  )
}
