import crypto from 'crypto-browserify'
import config from '../config';
import bs58 from 'bs58'
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useEffect, useState } from 'react';
export const updateStateObject = (setState, objectKey, value) => {
    setState((prevState) => ({
        ...prevState,
        [`${objectKey}`]: value
    }))
}


export const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export const showAlert = (setAlert, message, alert = 'danger') => {
    const id = crypto.randomBytes(16).toString('hex')
    setAlert((prevState) => ([
        ...prevState.slice(0, 3),
        { type: alert, message: message, id: id },
    ]))
    setTimeout(() => {
        hideAlert(setAlert, id)
    }, 10000);
}

export const hideAlert = (setAlert, id) => {
    setAlert((prevState) => ([
        ...prevState.filter(x => x.id !== id)
    ]))
}

export const loadTokens = async (walletAddress, projectId, setTokens, setActiveTokens) => {
    try {
        const token = await fetch(`${config.apiURL}/get-tokens`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress ? walletAddress.toString() : walletAddress,
                projectId: projectId
            })
        })
        const tokenList = await token.json()
        if (tokenList.error) setTokens([])
        if (tokenList.tokens) {
            setTokens(tokenList.tokens)
            setActiveTokens(tokenList.tokens[0])
        }
    } catch {
        setTokens([])
    }
}

export const verifyWallet = async (signMessage, setAlert, setDisplayModal, walletAddress, projectId, socket) => {
    try {
        const nonceReq = await fetch(`${config.apiURL}/get-nonce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: projectId
            })
        })
        const nonce = await nonceReq.json()
        if (nonce.status === 'error') return showAlert(setAlert, nonce.message)
        else {
            let signature = await signMessage(new TextEncoder().encode(`${nonce.data.nonce}`))
            if (!signature) return showAlert(setAlert, `An Error occured while signing`)
            else {
                signature = bs58.encode(signature)
                localStorage.setItem('signature_sign_in', signature)
                localStorage.setItem('access_id', nonce.data.id)
                socket.emit('info', { walletAddress: walletAddress, projectId: projectId, nonceId: localStorage.getItem('access_id'), signature: localStorage.getItem('signature_sign_in') })
                socket.walletSigned = true

                setDisplayModal(false)
                showAlert(setAlert, `Successfully signed in`, 'success')
                window.location.reload()
            }
        }
    } catch (err) {
        console.log(err)
        showAlert(setAlert, err.message ? err.message : 'An unknown error occured')
    }
}

export const setNewBalance = (setActiveToken, setTokens, newBalance, tokenId, checkDecimal) => {
    setActiveToken((prev) => {
        if (prev.tokenId === tokenId) {
            return { ...prev, balance: !checkDecimal ? newBalance : Number(parseFloat(newBalance / (prev?.data.decimals || LAMPORTS_PER_SOL)).toFixed(10)) }
        }
        return prev
    })
    setTokens((prev) => {
        const token = prev.find(x => x.tokenId === tokenId)
        if (token) token.balance = newBalance
        if (checkDecimal) token.balance = Number(parseFloat(newBalance / (token?.data.decimals || LAMPORTS_PER_SOL)).toFixed(10))
        return prev
    })

}

export function nFormatter(num) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    if (num < 1) return num
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return item ? (parseFloat((num / item.value).toFixed(10))) + item.symbol : num;
}



export const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
}

