import { MenuItem, Select } from '@mui/material';
import React, { useContext } from 'react'
import { GameContext } from '../../pages/Game';
import Logo from './../../assets/img/navbar/logo.png'
import crypto from 'crypto-browserify'
import ConnectWalletButton from './wallet-adapter/ConnectWalletButton.js'
import { useWallet } from '@solana/wallet-adapter-react'
import depositIcon from './../../assets/img/utility/depositIcon.png'
import SolKillerBunnies from './../../pages/casino/customUtility/SolKillerBunnies.js'
export default function Navbar({setDisplayModal}) {
    const { activeTokenState, tokens, project } = useContext(GameContext)
    const [activeToken, setActiveToken] = activeTokenState

    const { publicKey } = useWallet()

    // const toggleMobileNavbar = () => {
    //     if (!document.getElementById('navbar-checkbox-id')) return
    //     const checked = document.getElementById('navbar-checkbox-id').checked
    //     if (!checked) {
    //         document.getElementById('mobile-navbar').style.height = "100vh"
    //     }
    //     if (checked) {
    //         document.getElementById('mobile-navbar').style.height = "0vh"
    //     }
    // }

    // const closeMobileNavbar = () => {
    //     if (!document.getElementById('navbar-checkbox-id')) return

    //     document.getElementById('mobile-navbar').style.height = "0vh"
    // }

    return (
        <>
            <div className='navbar' id='navbar'>
                <div className='navbar-content'>
                    <div style={{ marginRight: '20px' }}>
                        <img src={project.logo} alt="Logo" id='navbar-logo'/>
                    </div>
                    {project.id === 'solkillerbunnies' && (<SolKillerBunnies/>)}

                    {publicKey && (
                        <div className='navbar-side-links'>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className='font-montserrat deposit-icon' onClick={() => setDisplayModal(true)}>
                                    <div style={{
                                        padding: '0px 20px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: '700',
                                        fontSize: '1.8rem'
                                    }}>
                                        +
                                    </div>
                                </div>

                                <div className='font-montserrat'
                                    style=
                                    {{
                                        backgroundColor: 'rgb(44 44 79)',
                                        color: '#fff',
                                        fontSize: '1.1rem',
                                        margin: 'auto',
                                        opacity: '1',
                                        fontWeight: '500',
                                        minHeight: '100%'
                                    }}>
                                    <div style={{ padding: '7px 20px' }}>
                                        {Number(+(activeToken.balance.toFixed(5))).toLocaleString('en')} {activeToken.tokenSymbol}
                                    </div>
                                </div>
                                <Select
                                    value={activeToken.tokenId}
                                    id='navbar-link-token-option'
                                    style={{ color: 'white', margin: 'auto', backgroundColor: 'rgb(110 110 206)', opacity: `100%`, borderRadius: '0px', fontWeight: '600', borderTopRightRadius: '30px', minHeight: '100%' }}
                                    className='font-signika'
                                    onChange={(event) => setActiveToken(tokens.find(x => x.tokenId === event.target.value))}
                                >
                                    {tokens.map(x => {
                                        return (
                                            <MenuItem key={crypto.randomBytes(16).toString('base64')} value={x.tokenId} style={{ backgroundColor: 'rgb(126 108 255)', color: 'white', margin: '0px', fontSize: '1.1rem', textTransform: 'none' }}>
                                                <div className='navbar-token-balance'><img src={x.tokenLogo} style={{ height: '1.3rem' }} alt={`${x.tokenSymbol} Logo`} /></div>
                                                </MenuItem>
                                        )
                                    })}
                                </Select>

                            </div>
                        </div>
                    )}
                    <div>
                        <ConnectWalletButton />
                    </div>
                    {/* </div> */}


                </div>
            </div>

        </>
    )
}
