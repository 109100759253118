import styled from '@emotion/styled'
import React from 'react'

export default function Luckyball({ number, style }) {
    const colours = [
        { limit: 10, color: '#f28c33' },
        { limit: 20, color: '#ff4040' },
        { limit: 30, color: '#1cffb7' },
        { limit: 40, color: '#57f2fa' },
        { limit: 50, color: '#61b8ff' },
        { limit: 60, color: '#7585ff' },
        { limit: 70, color: '#a86eff' }
    ]
    const color = colours.find(x => number <= x.limit)
    const PowerBallContainer = styled.div`
    background-color: var(--casino__powerball__background-color);
    --casino__powerball__colour: ${color.color};
    --casino__powerball__background-color: white;
    border: 0.3rem solid var(--casino__powerball__colour);
    :hover {
        background-color: var(--casino__powerball__colour);
    }
    `
    return (
        <PowerBallContainer className='casino__powerball__ball' style={style ? style : {}}>
            {number}
        </PowerBallContainer>
    )
}
