import React from 'react';
import './index.css';
import SolanaWalletProvider from './components/utility/wallet-adapter/SolanaWalletProvider';
import Game from './pages/Game';
import { Route, Routes } from 'react-router-dom';
import './css/utility.css'
import 'boxicons'

export default function Casino() {
  return (
    <div className='casino__container'>
    <Routes>
      <Route path='/:id/*' element={(<SolanaWalletProvider>
        <Game />
      </SolanaWalletProvider>)} />
      <Route path='/' element={(<SolanaWalletProvider>
        <Game />
      </SolanaWalletProvider>)} />
    </Routes>
    </div>
  )
}
