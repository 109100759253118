import React, { useContext, useEffect, useState } from 'react'
import { nFormatter } from '../../../javascript/utility'
import Luckyball from './Luckyball'
import preloader from './../../../assets/img/utility/preloader.gif'
import PowerBallImg from './../../../assets/img/casino/CasinoRouterIcons/yololottery.gif'
import { PowerballTimer } from './PowerballTimer'
import { GameContext } from '../../Game'
import CheckIcon from '@mui/icons-material/Check';
import { useWallet } from '@solana/wallet-adapter-react'
import config from '../../../config'
import { buyPowerballTickets } from '../../../javascript/casino'
import { Switch, ToggleButton } from '@mui/material'
import SubcontainerBg from './../../../assets/img/casino/powerball/subcontainer-bg.png'
import YoloLotteryTitleImg from './../../../assets/img/casino/powerball/yoloLotteryTitle.png'
import TimerImg from './../../../assets/img/casino/powerball/timer.png'

export default function PowerBall() {
  const { project, activeTokenState, setTokens, alertState, displaySignModalState } = useContext(GameContext)
  const [, setDisplaySignModal] = displaySignModalState
  const [, setAlert] = alertState
  const { publicKey } = useWallet()
  const [activeToken, setActiveToken] = activeTokenState
  const [numberOfTickets, setNumberOfTickets] = useState(1)
  const [displayPreviousRounds, setDisplayPreviousRounds] = useState(false)
  const [displayMyTickets, setDisplayMyTickets] = useState(false)
  const [displayBuyTicketsModal, setDisplayBuyTicketsModal] = useState(false)

  const [quickpick, setQuickpick] = useState(true)
  const [lotteryData, setLotteryData] = useState()
  const [activeLottery, setActiveLottery] = useState()

  const [disableBuyBtn, setDisableBuyBtn] = useState(false)
  const [tickets, setTickets] = useState([
    {
      id: 'xxxxx',
      data: {
        ticketNumber: 1,
        choosenNumbers: [1, 4, 16, 30, 56, 60],
        choosenPowerballNumber: 12
      }
    },
    {
      id: 'xxxxx',
      data: {
        ticketNumber: 1,
        choosenNumbers: [2, 5, 17, 31, 58, 60],
        choosenPowerballNumber: 15
      }
    }
  ])

  useEffect(() => {
    async function getLotteryDate() {
      try {
        let req = await fetch(`${config.apiURL}/get-live-games`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            projectId: project.id,
            tokenId: activeToken.tokenId,
            gameId: 'powerball'
          })
        })
        let res = await req.json()
        if (res.error) {
          return setLotteryData([])
        } else {
          const newLotteryData = res.sort((a, b) => b.endingAt - a.endingAt)
          setActiveLottery(newLotteryData[0])
          console.log(newLotteryData)
          return setLotteryData(newLotteryData)
        }
      } catch {
        return setLotteryData([])
      }
    }
    getLotteryDate()
  }, [activeToken, project])

  const buyTickets = async function () {
    buyPowerballTickets(setAlert, setDisableBuyBtn, publicKey, activeToken, project.id, setDisplaySignModal, activeLottery, numberOfTickets, setActiveToken, setTokens, quickpick, setDisplayBuyTicketsModal, displayBuyTicketsModal)
  }
  if (!lotteryData) {
    return (<div style={{ minWidth: '100vw', height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
        <img src={preloader} style={{ width: '120px', maxWidth: '60vw' }} alt='preloader' />
      </div>
    </div>)
  } else if (!lotteryData.length) {
    return (<div className='casino__powerball__container'>
      <div className='casino__powerball__subcontainer'>
        <img src={SubcontainerBg} className='casino__powerball__subcontainer-bg' alt="subcontainer" />
        <div className='casino__powerball__prize-pot-big-text font-share-tech' style={{ fontSize: '2rem', margin: '2rem' }}>
          No powerball round has started yet
        </div>
      </div>
    </div>)
  } else {
    return (
      <div className='casino__powerball__bg'>
        <div className='casino__powerball__container'>
          <div className='casino__powerball__subcontainer'>
            <img src={SubcontainerBg} className='casino__powerball__subcontainer-bg' alt="subcontainer" />

            <div className='casino__powerball__subcontainer-height-fit-content'>
              {lotteryData.find(x => x.endingAt > activeLottery.endingAt) ? <button onClick={() => setActiveLottery(lotteryData.sort((a, b) => b.endingAt - a.endingAt)[0])} className='casino__powerball__button' style={{ margin: '0.5rem 0px' }}><span>BACK TO ONGOING ROUND</span><i></i></button> : <></>}
              <div className='casino__powerball__prize-text-container'>
                <div className='casino__powerball__prize-pot-big-text font-share-tech'>
                  {/* <img src={YoloLotteryTitleLeftImg} alt="left border" className='casino__powerball__prize-pot-title-left-img'/> */}
                  <div className='casino__powerball__prize-pot-big-text-inner'>
                    {nFormatter(activeLottery.data.lotteryPot)} {activeToken.tokenSymbol}
                  </div>
                  {/* <img src={YoloLotteryTitleRightImg} alt="left border" className='casino__powerball__prize-pot-title-right-img'/> */}
                </div>
              </div>
              {activeLottery.endingAt > Date.now() ? <PowerballTimer deadline={activeLottery.endingAt} /> : !activeLottery.data.winningNumbers ? (
                <div className='casino__powerball__status-purple-container'>
                  <img src={TimerImg} className='casino__powerball__status-purple-container-bg' alt="subcontainer" />

                  <div className='casino__powerball__status-purple-container-title-text font-signika'>ENDED</div>
                </div>
              ) : (
                <div className='casino__powerball__status-purple-container'>
                  <img src={TimerImg} className='casino__powerball__status-purple-container-bg' alt="subcontainer" />

                  <div className='casino__powerball__status-purple-container-title-text font-signika'>WINNING NUMBERS</div>
                  <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', alignItems: 'center' }}>
                    {activeLottery?.data?.winningNumbers?.map((x, i) => {
                      return (<div key={i} style={i === 6 ? { marginLeft: '1rem' } : {}}><Luckyball style={{ height: '1.2rem', width: '1.2rem', fontSize: '1rem' }} number={x} /></div>)
                    })}
                    <div style={{ marginLeft: '1rem' }}><Luckyball number={activeLottery.data.powerBallNumber} style={{ height: '1.2rem', width: '1.2rem', fontSize: '1rem' }} /></div>
                  </div>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row wrap' }}>
                <HowToModal />

                <button onClick={() => setDisplayPreviousRounds(true)} className='casino__powerball__button' style={{ '--clr': '#FF44CC', margin: '0.2rem 0.5rem 0.2rem 0px' }}><span>OTHER ROUNDS</span><i></i></button>
                <PreviousRoundsModal displayModal={displayPreviousRounds} setDisplayModal={setDisplayPreviousRounds} lotteryData={lotteryData} activeLotteryState={[activeLottery, setActiveLottery]} />
                <button onClick={() => setDisplayMyTickets(true)} className='casino__powerball__button' style={{ '--clr': '#FF44CC', margin: '0.2rem 0.5rem 0.2rem 0px' }} ><span>MY TICKETS</span><i></i></button>
                <MyTickets setTickets={setTickets} tickets={tickets} displayModal={displayMyTickets} setDisplayModal={setDisplayMyTickets} activeLottery={activeLottery} />

              </div>
            </div>
          </div>


          <div className='casino__powerball__subcontainer'>
            <img src={SubcontainerBg} className='casino__powerball__subcontainer-bg' alt="subcontainer" />

            <div className='casino__powerball__subcontainer-title font-share-tech'>BUY TICKETS</div>
            <form onSubmit={(event) => {
              event.preventDefault()
              buyTickets()
            }} className='utility__forms__grey-form' style={{ padding: '2rem 0px 0px 0px' }}>
              <div className='field required' style={{ margin: '0 0 0.5em 0' }}>
                <div className='label font-share-tech' htmlFor='casino__powerball__buy-tickets-number-of-tickets' style={{ padding: '0px' }}>Number Of Tickets</div>
                <input required className='text-input' id='casino__powerball__buy-tickets-number-of-tickets' value={numberOfTickets} onChange={(event) => {
                  if (Number(event.target.value) <= 0) {
                    setNumberOfTickets('')
                  } else if (Number(event.target.value) <= 100) {
                    setNumberOfTickets(Math.floor(Number(event.target.value)))
                  } else {
                    setNumberOfTickets(100)
                  }
                }} type={'number'} />
              </div>

              <div className='field' style={{ margin: '0 0 0.5em 0' }}>
                <div className='label font-share-tech' htmlFor='casino__powerball__buy-tickets-price-per-ticket' style={{ padding: '0px' }}>{`Ticket Price`}</div>
                <input required className='text-input' id='casino__powerball__buy-tickets-price-per-ticket' value={`${project?.data?.powerball?.ticketPrice ? project.data.powerball.ticketPrice : activeToken.data.tokenValue ? 0.5 * activeToken.data.tokenValue : 0.5} ${activeToken.tokenSymbol}`} disabled />
              </div>

              <div className='field' style={{ margin: '0 0 0.5em 0' }}>
                <div className='label font-share-tech' htmlFor='casino__powerball__buy-tickets-price-per-ticket' style={{ padding: '0px' }}>{`Total Cost`}</div>
                <input required className='text-input' id='casino__powerball__buy-tickets-price-per-ticket' value={`${activeToken.data.tokenValue ? (project?.data?.powerball?.ticketPrice ? project.data.powerball.ticketPrice : 0.5) * numberOfTickets * activeToken.data.tokenValue : (project?.data?.powerball?.ticketPrice ? project.data.powerball.ticketPrice : 0.5) * numberOfTickets} ${activeToken.tokenSymbol}`} disabled />
              </div>
              <div style={{ display: 'flex', flexFlow: 'row wrap', gap: '0.5rem', width: '100%', alignItems: 'center' }}>
                <div className='label font-share-tech' style={{ padding: '0px' }}>{`Quick Pick`}</div>
                <Switch checked={quickpick} onChange={() => setQuickpick(!quickpick)} />
              </div>
              <ChooseNumbers buyTickets={buyTickets} disableBuyBtn={disableBuyBtn} numberOfTickets={numberOfTickets} displayModal={displayBuyTicketsModal} setDisplayModal={setDisplayBuyTicketsModal} />
              <button className='casino__powerball__button' style={{ margin: '0.5rem auto' }} disabled={Date.now() > activeLottery.endingAt || disableBuyBtn} ><span>BUY {numberOfTickets} Tickets</span><i></i></button>
            </form>
          </div>
        </div >
      </div>
    )
  }
}

const HowToModal = () => {
  const [displayModal, setDisplayModal] = useState(false)
  return (
    <>
      <button onClick={() => setDisplayModal(true)} className='casino__powerball__button' style={{ '--clr': '#FF44CC', margin: '0.2rem 0.5rem 0.2rem 0px' }}><span>HOW TO PLAY</span><i></i></button>
      <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
        <div className='modal-bg' onClick={() => setDisplayModal(false)}></div>
        <div className='modal-container'>
          <div className='modal font-montserrat' style={{ color: 'white', padding: '1.5rem', justifyContent: 'left', overflowY: 'scroll' }}>
            <div style={{ display: 'flex', marginLeft: '90%', marginTop: '10px' }}>
              <div className='modal-close-btn' onClick={() => setDisplayModal(false)}>
                <div style={{ lineHeight: '1.2em', color: 'black' }}>✖</div>
              </div>
            </div>

            <img src={PowerBallImg} style={{ width: '250px', maxWidth: '80%', display: 'block', margin: 'auto' }} />
            <div style={{
              fontSize: '1.5rem',
              fontWeight: '700',
              textTransform: 'uppercase',
              textAlign: 'center'
            }}>HOW TO PLAY?</div>
            <ul>
              <li style={{ marginTop: '0.5rem' }}>Powerball is played by selecting six numbers between 1-69 and one “Powerball” number between 1-26. Each number is on a special ball that is randomly selected by a machine during the drawing. Your ultimate goal is to match every number selected perfectly, which will win you the jackpot. However, there are other winning combinations of numbers that will win smaller prizes</li>
              <li style={{ marginTop: '0.5rem' }}>Once you buy any ticket, it will ask you to choose your numbers but if you wish you can choose the quick pick option which will randomly choose your numbers.</li>
              <li style={{ marginTop: '0.5rem' }}></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

const PreviousRoundsModal = ({ displayModal, setDisplayModal, lotteryData, activeLotteryState }) => {
  const [activeLottery, setActiveLottery] = activeLotteryState
  const { activeTokenState } = useContext(GameContext)
  const [activeToken] = activeTokenState
  return (
    <>
      <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
        <div className='modal-bg' onClick={() => setDisplayModal(false)}></div>
        <div className='modal-container'>
          <div className='modal font-montserrat' style={{ color: 'white', padding: '1.5rem', justifyContent: 'left', overflowY: 'scroll', maxWidth: 'fit-content' }}>
            <div>
              <div className='modal-close-btn' style={{ float: 'right', marginBottom: '0.5rem' }} onClick={() => setDisplayModal(false)}>
                <div style={{ lineHeight: '1.2em', color: 'black' }}>✖</div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row wrap' }}>
              {lotteryData.filter(x => x.liveGameId !== activeLottery.liveGameId).sort((a, b) => b.endingAt - a.endingAt).map((x, i) => {
                return (
                  <div onClick={() => {
                    setActiveLottery(x)
                    setDisplayModal(false)
                  }} key={i} className='casino__powerball__subcontainer casino__powerball__subcontainer-hover' style={{ borderRadius: '0px', margin: '0.2rem', width: '200px', cursor: 'pointer' }}>
                    <div className='casino__powerball__subcontainer-height-fit-content' style={{ width: 'fit-content' }}>
                      <div className='casino__powerball__prize-text-container'>
                        <div className='casino__powerball__prize-pot-big-text font-share-tech' style={{ fontSize: '2rem' }}>
                          ROUND #{x.data.lotteryNumber}
                        </div>

                        <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'center', textAlign: 'center', flexFlow: 'row wrap' }}>
                          <div className='casino__powerball__prize-pot-small-text font-share-tech' style={{ fontSize: '1.3rem' }}>
                            Prize Pot:
                          </div>
                          <div className='casino__powerball__prize-pot-small-text font-share-tech' style={{ fontSize: '1.3rem', color: '#00fff3' }}>
                            {nFormatter(x.data.lotteryPot)} {activeToken.tokenSymbol}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



const MyTickets = ({ displayModal, setDisplayModal, tickets, activeLottery, setTickets }) => {
  const { publicKey } = useWallet()
  const { project, activeTokenState } = useContext(GameContext)
  const [activeToken] = activeTokenState
  useEffect(() => {
    async function getLotteryDate() {
      try {
        let req = await fetch(`${config.apiURL}/get-live-games-entries`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            projectId: project.id,
            tokenId: activeToken.tokenId,
            gameId: 'powerball',
            walletAddress: publicKey,
            liveGameId: activeLottery.liveGameId
          })
        })
        let res = await req.json()
        if (res.error) {
          return setTickets([])
        } else {
          const newLotteryData = res.sort((a, b) => b.data.ticketNumber - a.data.ticketNumber)
          setTickets(newLotteryData[0])
          return setTickets(newLotteryData)
        }
      } catch {
        return setTickets([])
      }
    }
    getLotteryDate()
  }, [activeLottery])

  return (
    <>
      <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
        <div className='modal-bg' onClick={() => setDisplayModal(false)}></div>
        <div className='modal-container'>
          <div className='modal font-montserrat' style={{ color: 'white', padding: '1.5rem', justifyContent: 'left', overflowY: 'scroll', maxWidth: 'fit-content' }}>
            <div>
              <div className='modal-close-btn' style={{ float: 'right', marginBottom: '0.5rem' }} onClick={() => setDisplayModal(false)}>
                <div style={{ lineHeight: '1.2em', color: 'black' }}>✖</div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'row wrap' }}>
              {tickets.sort((a, b) => b.data.ticketNumber - a.data.ticketNumber).map((x, i) => {
                return (
                  <div key={i} className='casino__powerball__subcontainer casino__powerball__subcontainer-hover' style={!activeLottery.data.winningNumbers ? { borderRadius: '0px', margin: '0.2rem', width: '200px', cursor: 'pointer' } : checkTicketWin(activeLottery, x) ? { borderRadius: '0px', margin: '0.2rem', width: '200px', cursor: 'pointer', border: '1px solid #00ff84', background: '#1a6a424f' } : { borderRadius: '0px', margin: '0.2rem', width: '200px', cursor: 'pointer', border: '1px solid rgb(255 0 0)', background: 'rgba(255, 116, 116, 0.18)' }}>
                    <div className='casino__powerball__subcontainer-height-fit-content' style={{ width: 'fit-content' }}>
                      <div className='casino__powerball__prize-text-container'>
                        <div className='casino__powerball__prize-pot-big-text font-share-tech' style={{ fontSize: '2rem' }}>
                          Ticket #{x.data.ticketNumber}
                        </div>
                        <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', alignItems: 'center' }}>
                          {x?.data?.choosenNumbers?.map((x, i) => {
                            return (<div key={i} style={i === 6 ? { marginLeft: '1rem' } : {}}><Luckyball style={{ height: '1.2rem', width: '1.2rem', fontSize: '0.8rem' }} number={x} /></div>)
                          })}
                          <div style={{ marginLeft: '1rem' }}><Luckyball style={{ height: '1.2rem', width: '1.2rem', fontSize: '0.8rem' }} number={x.data.choosenPowerballNumber} /></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const checkTicketWin = (activeLottery, ticket) => {
  const winningMainNumbers = activeLottery.data.winningNumbers
  const winningPowerballNumbers = activeLottery.data.powerBallNumber
  const choosenMainNumbers = ticket.data.choosenNumbers
  const choosenPowerballNumber = ticket.data.choosenPowerballNumber

  const checkSameMainNumbers = winningMainNumbers.filter(x => choosenMainNumbers.includes(x))
  if (checkSameMainNumbers.length >= 2 && winningPowerballNumbers === choosenPowerballNumber || checkSameMainNumbers.length >= 4) {
    return true
  } else return false
}

const ChooseNumbers = ({ displayModal, setDisplayModal, disableBuyBtn, buyTickets, numberOfTickets }) => {
  const numbersOneTo69 = []
  for (var i = 1; i <= 69; i++) {
    numbersOneTo69.push(i)
  }

  const numbersOneTo25 = []
  for (var i = 1; i <= 25; i++) {
    numbersOneTo25.push(i)
  }

  return (
    <>
      <div className='modal-wrapper' style={!displayModal ? { display: 'none' } : {}}>
        <div className='modal-bg' onClick={() => setDisplayModal(false)}></div>
        <div className='modal-container'>
          <div className='modal font-montserrat' style={{ color: 'white', padding: '1.5rem', justifyContent: 'left', overflowY: 'scroll', maxWidth: 'fit-content' }}>
            <div>
              <div className='modal-close-btn' style={{ float: 'right', marginBottom: '0.5rem' }} onClick={() => setDisplayModal(false)}>
                <div style={{ lineHeight: '1.2em', color: 'black' }}>✖</div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexFlow: 'column wrap' }}>
              <div className='casino__powerball__subcontainer casino__powerball__subcontainer-hover' style={{ borderRadius: '0px', margin: '0.2rem', width: '450px', cursor: 'pointer', display: 'flex', flexFlow: 'row wrap' }}>
                <div style={{ width: '100%' }} className='font-share-tech casino__powerball__choose-numbers-text'>
                  Choose six main numbers
                </div>
                {numbersOneTo69.map((x, i) => {
                  return (<SelectBallInput inputClass={'casino__powerball__main-numbers-input-radio-btn'} max={6} x={x} key={i} />)
                })}
              </div>

              <div className='casino__powerball__subcontainer casino__powerball__subcontainer-hover' style={{ borderRadius: '0px', margin: '0.2rem', width: '450px', cursor: 'pointer', display: 'flex', flexFlow: 'row wrap' }}>
                <div style={{ width: '100%' }} className='font-share-tech casino__powerball__choose-numbers-text'>
                  Choose powerball Number
                </div>
                {numbersOneTo25.map((x, i) => {
                  return (<SelectBallInput x={x} inputClass="casino__powerball__powerball-number-input-radio-btn" max={1} key={i} />)
                })}
              </div>
              <button className='casino__powerball__button' style={{ margin: '0.5rem auto' }} disabled={disableBuyBtn} ><span>BUY {numberOfTickets} Tickets</span><i></i></button>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const SelectBallInput = ({ x, max, inputClass }) => {
  const [checked, setChecked] = useState(false)
  return (<div className='casino__powerball__numbers-input'>
    <input type={"radio"} onChange={() => {
      return
    }} style={{ display: 'none' }} id={`${inputClass}-${x}`} checked={checked} className={`casino__powerball__main-numbers-input ${inputClass}`} />
    <label onClick={() => {
      const elements = document.getElementsByClassName(inputClass)
      const checkedElements = []
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].checked) {
          checkedElements.push(elements[i].id.replace('casino__powerball__numbers-input-', ''))
        }
      }
      if (checkedElements.length < max) {
        setChecked(!checked)
      } else {
        if (checked === true) {
          setChecked(false)
        }
      }
    }} htmlFor={`${inputClass}-${x}`}>
      <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
        <Luckyball number={x} />
      </div>
    </label>
  </div>)
}