import React, { useEffect, useState } from 'react'
import config from '../../../config'
export default function SolKillerBunnies() {
  const [communityFund, setCommunityFund] = useState()

  const getCommunityFund = async () => {
    try {
      const funds = await fetch(`${config.apiURL}/get-community-funds-skb`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            reset: false
          }
        })
      })
      const fundsJson = await funds.json()
      if (fundsJson.status === 'success') {
        setCommunityFund(fundsJson.data.amount)
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getCommunityFund()
  }, [])
  return (
    <>
      {communityFund && (
        <div className='community-funds-container'>
        <div className="community-funds">
          <div className="top-item">
            <div className="funds-text">
              Community Funds
            </div>
            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.132431 17.3542L4.00164 13.7316C4.15118 13.5912 4.34419 13.5211 4.53452 13.5211H22.4639C22.7915 13.5211 22.9139 13.9282 22.6651 14.1529L18.8013 17.7753C18.6464 17.9157 18.4519 18 18.2671 18H0.333571C0.00728578 18 -0.117721 17.5929 0.132431 17.3542ZM4.2436 10.9937L1.02428 7.38521C0.814918 7.14652 1.01476 6.75345 1.33968 6.75345H19.2622C19.4634 6.75345 19.6347 6.82369 19.7598 6.96409L22.9805 10.5865C23.1912 10.8112 22.9927 11.2184 22.6651 11.2184L4.73981 11.2325C4.54676 11.2185 4.36868 11.1342 4.2436 10.9937ZM19.4662 4.46482H1.54356C1.21864 4.46482 1.08814 4.05771 1.34101 3.83306L5.20474 0.210472C5.35565 0.0700665 5.54462 0 5.7431 0H23.667C23.992 0 24.1185 0.40711 23.8669 0.63176L20.0059 4.25418C19.855 4.39458 19.6619 4.46482 19.4662 4.46482Z" fill="url(#537.7420095988637)">
              </path>
              <defs>
                <linearGradient id="537.7420095988637" x1="17" y1="1" x2="8" y2="18" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#6DDCFF">
                  </stop>
                  <stop offset="1" stop-color="#BF6DFF">
                  </stop>
                </linearGradient>
              </defs>
            </svg>
            <p className="sol-balance">{communityFund} <span> SOL</span></p>
          </div>
        </div>
        </div>)}
    </>
  )
}
