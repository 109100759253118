import React, { createContext, useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router'
import GameRouter from './GameRouter'
import { useWallet } from '@solana/wallet-adapter-react'
import Navbar from '../components/utility/Navbar';
import { loadTokens } from '../javascript/utility';
import preloader from './../assets/img/utility/preloader.gif'
import { SignModal, WithdrawDepositModal } from '../components/utility/Modals';
import Alert from '../components/utility/Alert';
import Casino from './casino/Casino';
import { io } from "socket.io-client";
import config from '../config';
import Logo from './../assets/img/yolo/logo.jpg'
import { Helmet } from 'react-helmet';
import Games from './games/Games';
export const GameContext = createContext({
    project: {
        name: "BuffBunny",
        id: "buffbunny",
        description: "The Buff Bunny is a collection with a total supply of 5,000 NFTs integrated with the Solana blockchain. Baby bunny can evolve into a strong Buff bunny. The evolution is performed in three stages.",
        logo: "https://www.buffbunny.art/favicon.ico",
        createdAt: 1670667158684,
        owner: "64mHedpWkzbJKhJh6Z6WWkFiTXBHtM7sXMPR2a5qFJAF"
    },
    tokens: [],
    websocket: WebSocket.prototype,
    activeTokenState: [],
    alertState: [],
    displaySignModalState: [],
    socket: undefined,
    setTokens: undefined
})

export default function Game() {
    const [displayWithdrawDepositModal, setDisplayWithdrawDepositModal] = useState(false)
    const { publicKey } = useWallet()
    const [displaySignModal, setDisplaySignModal] = useState((!localStorage.getItem('access_id') || !localStorage.getItem('signature_sign_in')) && publicKey ? true : false)
    const params = useParams()
    const [projectCss, setProjectCss] = useState()

    const [project] = useState(projects.find(x => x.id === 'etalabs2000'))
    const [socket, setSocket] = useState()
    const [tokens, setTokens] = useState()
    const [activeToken, setActiveToken] = useState()
    const [alert, setAlert] = useState([])
    useEffect(() => {
        if (project) {
            loadTokens(publicKey, project.id, setTokens, setActiveToken)
            setDisplaySignModal((!localStorage.getItem('access_id') || !localStorage.getItem('signature_sign_in')) && publicKey ? true : false)
        }
    }, [publicKey, project.id])
    useEffect(() => {
        if (project && project.cssFile) {
            const setCssFile = async () => {
                if (project.cssFile) {
                    const cssFile = await fetch(project.cssFile)
                    if (cssFile) {
                        const css = await cssFile.text()
                        if (css) {
                            setProjectCss(css)
                        }
                    }
                } else {
                    setProjectCss()
                }
            }
            setCssFile(project.cssFile)
        }
    }, [project])

    useEffect(() => {
        connectWs()
        async function connectWs() {
            const socket = io(config.apiURL);
            socket.on("connect", async () => {
                setSocket(socket)
            });
            socket.on('reconnect', async () => {
                setSocket(socket)
            })
            socket.on('nonce', async () => {
                setDisplaySignModal(true)
            })
        }
    }, [publicKey])
    if (!tokens || !activeToken || !socket || !project) {
        return (
            <div style={{ minWidth: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className='font-signika' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2.5rem', fontWeight: '700', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
                    <img src={preloader} style={{ width: '250px', maxWidth: '60vw' }} alt='preloader' />
                </div>
            </div>
        )
    } else {
        return (
            <>
                <Helmet>
                    <link rel="icon" href={project.logo} />
                    <meta
                        name="description"
                        content={project.description}
                    />
                    <meta name="title" content={project.name} />
                    <title>{project.name}</title>
                    <style>
                        {`body {
                              margin: 0;
                              width: 100%;
                              height: 100%;
                              overflow-x: hidden !important;
                              overflow-y: visible !important;
                              background: ${project.data.background||`#221450`};
                              background-position: center;
                              background-attachment: fixed;
                              background-repeat: no-repeat;
                              background-size: cover;                            
                        }`}
                    </style>
                    {projectCss && (
                        <style>
                            {projectCss}
                        </style>
                    )}
                </Helmet>
                <GameContext.Provider value={
                    {
                        project,
                        tokens,
                        setTokens: setTokens,
                        activeTokenState: [activeToken, setActiveToken],
                        alertState: [alert, setAlert],
                        displaySignModalState: [displaySignModal, setDisplaySignModal],
                        socket
                    }}>
                    <Navbar setDisplayModal={setDisplayWithdrawDepositModal} />
                    <BackForwardBtn />
                    <WithdrawDepositModal displayModal={displayWithdrawDepositModal} setDisplayModal={setDisplayWithdrawDepositModal} setDisplaySignModal={setDisplaySignModal} />
                    <SignModal displayModal={displaySignModal} setDisplayModal={setDisplaySignModal} />
                    <Alert />
                    {publicKey ? (
                        <Routes>
                            <Route path='/' element={<GameRouter />} />
                            <Route path='/casino/*' element={<Casino />} />
                            <Route path='/games/*' element={<Games />} />
                        </Routes>
                    ) : (
                        <div style={{ minWidth: '100vw', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='font-montserrat' style={{ borderRadius: '15px', color: 'white', display: 'flex', alignItems: 'center', textAlign: 'center', maxWidth: '80vw', fontSize: '2rem', fontWeight: '500', paddingLeft: '25px', paddingRight: '25px', paddingTop: '15px', paddingBottom: '15px', height: 'fit-content' }}>
                                You have not connected your wallet
                            </div>
                        </div>
                    )}
                </GameContext.Provider>
            </>
        )
    }
}

const BackForwardBtn = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='utility-flex-row' style={{ width: 'fit-content', margin: '0px 2vw' }}>
                <button href="#" className="utility-back-forward-btn" onClick={() => navigate(-1)}>
                    <i className='bx bx-chevron-left'></i>
                </button>
                <button href="#" className="utility-back-forward-btn" onClick={() => (navigate(1))}>
                    <i className='bx bx-chevron-right' ></i>
                </button>
            </div>
        </>
    )
}

const projects = [
    {
        "name": "ETA Labs",
        "id": "etalabs2000",
        "description": "",
        "logo": Logo,
        "createdAt": {
            "$numberLong": "1670667158684"
        },
        "owner": "",
        // "cssFile": "",
        "data": {
            fees: 2,
            noCoinFlipMultiplier: true,
            crash: {

            },
            includedGames: ['coinflip', 'crash', 'yololottery', 'racing'],
            images: {
                games: {
                    racing: 'https://arweave.net/gPQEfRB4dCi2dWZ7AaZT21YTe1umooKdBDkJPaAdlVU'
                }
            },
            routerNames: {
                games: {
                    racing: 'Fox Dash'
                }
            },
            coinFlip: {
                backImage: 'https://arweave.net/RMHWK2paMr9BaxqXkiDUGIKvb2qvnx7I5TNNprOIzTs?ext=png',
                frontImage: 'https://arweave.net/gT_woAZ-EXqm0z3HCQ3W9bKiQohKT3ZxChW1ibjp3Dg'
            },
            racing: {
                iconImage: 'https://arweave.net/j5dMIDqkIrDDqB9BosAHmwQ4mos4VLNtcV5zokhCxlg',
                backgroundImage: 'https://media.discordapp.net/attachments/895606843278262295/1107343163657568296/Utsav_A_background_for_a_fox_racing_game_basically_a_place_wher_0e45c445-0d1c-4c9e-bf2d-aa30b52912da.png?width=1358&height=602',
                tracks: [
                    { img: 'https://arweave.net/0AOh_fSwwKNAXXZSpF1oNI2yI7HRBvcgoKgbAuYtdLs', sprite: 'red' },
                    { img: 'https://arweave.net/0AOh_fSwwKNAXXZSpF1oNI2yI7HRBvcgoKgbAuYtdLs', sprite: 'blue' },
                    { img: 'https://arweave.net/0AOh_fSwwKNAXXZSpF1oNI2yI7HRBvcgoKgbAuYtdLs', sprite: 'brown' },
                    { img: 'https://arweave.net/0AOh_fSwwKNAXXZSpF1oNI2yI7HRBvcgoKgbAuYtdLs', sprite: 'green' },
                    { img: 'https://arweave.net/0AOh_fSwwKNAXXZSpF1oNI2yI7HRBvcgoKgbAuYtdLs', sprite: 'yellow' },
                ],
                sprites: [
                    { id: 'red', name: 'Red', running: 'https://arweave.net/gPQEfRB4dCi2dWZ7AaZT21YTe1umooKdBDkJPaAdlVU', standing: 'https://arweave.net/4CNI0CHf80DH-1Wikn99vP0Dnw0HMif-Jd6Ha-pzOXc' },
                    { id: 'blue', name: 'Blue', running: 'https://arweave.net/rVDZtFjtGff8zeNUQoRz9CKj5I3hvA-vyxog3XhlTpQ', standing: 'https://arweave.net/i-N4q-CmFxxLGrX2XsDKHbqx8K3Lef8fYh3869e7uxQ' },
                    { id: 'black', name: 'Black', running: 'https://arweave.net/dl4zpETFen775mjCVJcJpJPcH_G__WX3yniFmYzC2vc', standing: 'https://arweave.net/fOXlHvzjPq9Yck9hoVT5YZHW6DKMSYwGlb-10-h0utE' },
                    { id: 'green', name: 'Green', running: 'https://arweave.net/ysO7CH4I-pCwTwH4jUEZ_cDKq_j0YtnXL46RQE0wXXk', standing: 'https://arweave.net/CaoXm9ePju9Jh9IdX7IBiaFYOc0G2T2WyhKFUXMjkKc' },
                    { id: 'orange', name: 'Orange', running: 'https://arweave.net/o1ISx5WDvTl8Lc8f1EYakfTbDf1LpAVvqWt3kFq00_g', standing: 'https://arweave.net/_3-LlAZ7gFXHU2X0tLX1dCiScjDT6a6TjDggYLpRED4' },
                ]
            }


        }
    }
]
